@import 'drawer-variables';

.drawer {
  background-color: $drawer-bg-color;

  &.position-right {
    @extend .shadow-neutral-6-left;
  }

  &.position-bottom {
    @extend .shadow-neutral-6-top;
  }

  &-title {
    @extend .heading-30;

    .f-i {
      color: $drawer-title-icon-color;
    }
  }

  &-footer {
    box-shadow: $drawer-footer-box-shadow;
  }
}
