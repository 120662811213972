$form-check-box-shadow: $shadow-focused-level-1-properties rgba($color-primary-400, $shadow-focused-level-1-opacity);
$form-check-input-disabled-opacity: 0.4;
$form-check-input-disabled-opacity-checked: 0.6;
$form-check-input-disabled-bg-color: $color-default-200;

$form-radio-checked-bc: $color-default-500;
$form-radio-checked-bg-pressed: $color-primary-800;
$form-radio-checked-bg-disabled: $color-primary-400;

$form-switch-circle-bg: $color-base;
$form-switch-bg-opacity-disabled: 0.8;
$form-switch-bg-disabled: rgba($color-default-200, 0.7);
$form-switch-disabled-opacity-checked: 0.6;

$input-radio-disabled-color: $color-neutral-700;
$input-radio-disabled-opacity: 0.4;
$input-checked-bg-color: $color-primary-600;
$input-checked-hovered-bg-color: $color-primary-700;
$input-checked-disabled-bg-color: $color-primary-400;
$input-checked-bg-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32" version="1.1"><path style="stroke:none;fill-rule:evenodd;fill:white;fill-opacity:1;" d="M26.244 9.548l-13.813 14.274-8.007-8.274 1.82-1.762 6.187 6.393 11.993-12.393 1.821 1.762z"/></svg>');
$input-checked-image-bg-disabled: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32" version="1.1"><path style="stroke:none;fill-rule:evenodd;fill:white;fill-opacity:1;" d="M26.244 9.548l-13.813 14.274-8.007-8.274 1.82-1.762 6.187 6.393 11.993-12.393 1.821 1.762z"/></svg>');
$input-indeterminate-image-bg: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="32px" height="32px" viewBox="0 0 32 32" version="1.1"><path style="stroke:none;fill-rule:nonzero;fill:white;fill-opacity:1;" d="M 5.332031 14.667969 L 26.667969 14.667969 L 26.667969 16.800781 L 5.332031 16.800781 Z M 5.332031 14.667969"/></svg>');
$input-indeterminate-image-bg-disabled: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32" version="1.1"><path style=" stroke:none;fill-rule:nonzero;fill:white;fill-opacity:1;" d="M 5.332031 14.667969 L 26.667969 14.667969 L 26.667969 16.800781 L 5.332031 16.800781 Z M 5.332031 14.667969"/></svg>');

$form-check-checked-box-shadow-focus: $shadow-focused-level-1-properties
  rgba($color-primary-400, $shadow-focused-level-1-opacity);
$form-check-checked-bg-pressed: $color-primary-800;
$form-check-checked-bg-disabled: $color-primary-400;
$form-check-input-bc-hover: $color-default-700;
$form-check-input-bc-pressed: $color-default-800;
$form-check-input-bc-disabled: $color-default-300;
$form-check-input-indeterminate-bg: $input-indeterminate-image-bg, $color-primary-600;
$form-check-input-indeterminate-bg-hover: $input-indeterminate-image-bg, $color-primary-700;
$form-check-input-indeterminate-bg-pressed: $input-indeterminate-image-bg, $color-primary-600;
$form-check-input-indeterminate-bs-focus: $shadow-focused-level-1-properties
  rgba($color-primary-400, $shadow-focused-level-1-opacity);
$form-check-input-indeterminate-bg-disabled: $input-indeterminate-image-bg, $color-primary-400;

$form-check-label-color-disabled: $color-neutral-700;

$form-switch-bg: $color-default-300;
$form-switch-bg-hover: rgba($color-default-600, 0.5);
$form-switch-bg-focus: $form-switch-bg;
$form-switch-bg-pressed: rgba($color-default-600, 0.8);
