$kendo-grid-header-bg: $color-default-100;
$kendo-grid-header-text: $color-neutral-700;
$kendo-grid-alt-bg: $color-base;
$kendo-grid-bg: $color-base;
$kendo-grid-text: $body-color;
$kendo-grid-border: $color-neutral-100;
$kendo-grid-header-border: $kendo-grid-border;
$kendo-grid-hover-bg: $color-default-100;
$kendo-grid-selected-bg: $color-primary-50;
$kendo-grid-sticky-selected-bg: $color-primary-50;
$kendo-grid-sticky-selected-hover-bg: $color-primary-50;
$kendo-grid-sticky-selected-alt-bg: $color-primary-50;
$kendo-grid-sticky-alt-bg: $color-base;
$kendo-grid-sticky-hover-bg: $color-default-100;
$kendo-grid-sticky-footer-bg: $color-default-50;
$kendo-grid-sorting-indicator-text: $color-neutral-700;
$kendo-grid-footer-bg: $kendo-grid-bg;
$kendo-grid-highlighted-column-bg: $color-default-50;
$kendo-grid-highlighted-column-hovered-bg: $color-default-200;
$kendo-grid-sorted-bg: transparent;
$kendo-grid-sticky-border: $color-neutral-100;
