@import './chat-variables';

.chat-assistant-message {
  border-top-right-radius: $border-radius-4;
  border-bottom-left-radius: $border-radius-4;
  border-bottom-right-radius: $border-radius-4;
  background-color: $chat-assistant-message-bg;
}

.chat-user-message {
  border-top-left-radius: $border-radius-4;
  border-bottom-left-radius: $border-radius-4;
  border-bottom-right-radius: $border-radius-4;
  background-color: $chat-user-message-bg;
  color: $chat-user-message-color;
}

.chat-error-message {
  background-color: $chat-assistant-message-error-bg;
}

.chat-error-icon {
  color: $chat-assistant-message-error-icon-color;
}

.chat-message-label {
  color: $chat-message-label-color;
}
