.f-stacked-bar-container {
  .bar-segment {
    border-left-color: $color-base;
  }
}

[f-stacked-bar-series],
[f-positive-negative-bar-series],
[f-positive-negative-grouped-bar-series] {
  .selected .f-stacked-bar-wrapper {
    stroke: $rect-bar-selected-color;
  }
}

.f-stacked-bar-basis {
  fill: $f-stacked-bar-basis-bg;
}
