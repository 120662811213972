@import './validation-variables';

$validation-types-map: (
  valid: (
    bg: $form-valid-focus-bg,
    bc: $form-valid-focus-bc,
    hover-bg: $form-valid-hover-bg,
    hover-bc: $form-valid-hover-bc
  ),
  warning: (
    bg: $form-warning-focus-bg,
    bc: $form-warning-focus-bc,
    hover-bg: $form-warning-hover-bg,
    hover-bc: $form-warning-hover-bc
  ),
  invalid: (
    bg: $form-invalid-focus-bg,
    bc: $form-invalid-focus-bc,
    hover-bg: $form-invalid-hover-bg,
    hover-bc: $form-invalid-hover-bc
  )
);

$validation-feedback-map: (
  valid: $valid-feedback,
  warning: $warning-feedback,
  invalid: $invalid-feedback
);

@each $validation-type, $bg, $bc, $hover-bg, $hover-bc in $validation-types-map {
  $bg: map-deep-get($validation-types-map, $validation-type, 'bg');
  $bc: map-deep-get($validation-types-map, $validation-type, 'bc');
  $hover-bg: map-deep-get($validation-types-map, $validation-type, 'hover-bg');
  $hover-bc: map-deep-get($validation-types-map, $validation-type, 'hover-bc');

  %is-#{$validation-type}-hover {
    background-color: $hover-bg;
    border-color: $hover-bc;
  }

  %is-#{$validation-type}-focus {
    background-color: $bg;
    border-color: $bc;
    box-shadow: $form-valid-focus-bs;
  }
}
