$form-select-padding-y: 0.375rem;
$form-select-color: $body-color;
$form-select-bg: $input-bg;
$form-select-bg-size: 1.25rem 1.125rem;
$form-select-bg-position: right 0.5625rem center;
$form-select-box-shadow: null;
$form-select-indicator-padding: 2rem;
$form-select-border-width: $border-width-1;
$form-select-border-color: $input-border-color;
$form-select-focus-border-color: transparent;
$form-select-focus-box-shadow: null;
