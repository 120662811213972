$kendo-button-bg: $btn-secondary-bg;
$kendo-button-text: $color-neutral-900;
$kendo-button-focus-shadow: $shadow-focused-level-1-properties rgba($color-primary-400, $shadow-focused-level-1-opacity);
$kendo-button-hover-bg: $btn-secondary-bg-hover;
$kendo-button-hover-border: $color-default-400;
$kendo-button-active-bg: $color-default-400;
$kendo-button-active-shadow: $shadow-focused-level-1-properties
  rgba($color-primary-400, $shadow-focused-level-1-opacity);
$kendo-button-focus-shadow: $shadow-focused-level-1-properties rgba($color-primary-400, $shadow-focused-level-1-opacity);
$kendo-button-selected-bg: $color-primary-600;
$kendo-button-selected-text: $btn-primary-color;
$kendo-button-border-radius: $border-radius-1;
