@import 'firefly/styles/mixin';
@import './toast-variables';

.toast {
  box-shadow: $toast-box-shadow;

  &-danger {
    @include color-variant($toast-danger-bg, $toast-danger-bd);

    .toast-state-icon {
      color: $toast-danger-icon-color;
    }
  }

  &-success {
    @include color-variant($toast-success-bg, $toast-success-bd);

    .toast-state-icon {
      color: $toast-success-icon-color;
    }
  }

  &-warning {
    @include color-variant($toast-warning-bg, $toast-warning-bd);

    .toast-state-icon {
      color: $toast-warning-icon-color;
    }
  }

  &-info {
    @include color-variant($toast-info-bg, $toast-info-bd);

    .toast-state-icon {
      color: $toast-info-icon-color;
    }
  }

  .close {
    color: $toast-btn-close-color;
    &:hover {
      color: $toast-btn-close-hover-color;
    }
  }
}
