$form-feedback-font-size: 0.75rem;
$form-feedback-margin-top: 0;
$form-check-transition: none;
$form-check-inline-margin-end: 0;
$form-check-padding-start: 0;

$input-box-shadow: null;
$input-color: $body-color;
$input-focus-color: $body-color;
$input-padding-y: 0.375rem;
$input-padding-x-sm: 0.75rem;
$input-padding-x-lg: 0.75rem;
$input-height: 2rem;
$input-height-sm: 1.5rem;
$input-height-lg: 2.5rem;
$input-font-size-sm: 0.875rem;
$input-font-size-lg: 1rem;
$input-border-width: 1px;
$input-transition: border-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
