@import './mobile-inner-error-mixins';
@import './mobile-inner-error-variables';

.mobile-inner-error-wrapper {
  background-color: $body-bg;
}

.mobile-inner-error-img {
  @include empty-state($mobile-error-options);
}
