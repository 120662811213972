.f-line,
.f-circle {
  stroke: $line-stroke;
}

.f-area {
  fill: rgba($area-fill, $area-fill-opacity);

  & ~ .f-line,
  & ~ .f-circle {
    stroke: $area-stroke;
  }
}

.f-area-pointer-line {
  stroke: $area-pointer-line-stroke;

  &.selected {
    stroke: $area-pointer-line-stroke-selected;
  }
}
