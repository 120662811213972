@mixin ignore-cell-selection-color($base-bg: inherit) {
  background-color: $base-bg;
}

@mixin selected-cell-styles($bg-color, $bg-color-hover) {
  background-color: $bg-color;
  &:hover {
    background-color: $bg-color-hover;
  }
}
