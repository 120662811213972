@import './input-variables';
@import './input-mixins';
@import './input-placeholder-selectors';
@import './../validation-styles/validation-maps';

.input-group {
  &-right-icon,
  &-side-icons {
    .input-wrapper .f-i,
    > .f-i:last-of-type {
      &:hover {
        color: $form-active-icon-color-hover;
      }

      &.disabled {
        &:hover {
          opacity: $form-input-opacity-disabled;
        }
      }
    }
  }

  &-right-icon,
  &-left-icon,
  &-side-icons {
    @include input-group-with-icon;

    &:hover {
      .form-control {
        @extend %form-control-hover;

        &.disabled,
        &:disabled {
          @extend %form-control-disabled;
        }

        &.is-warning {
          @extend %is-warning-hover;
        }
        &.is-valid {
          @extend %is-valid-hover;
        }
        &.is-invalid {
          @extend %is-invalid-hover;
        }
      }

      .form-control-outline {
        @extend %form-control-outline-hover;

        &.disabled,
        &:disabled {
          @extend %form-control-outline-disabled;
        }
      }
    }
  }

  &-right-icon,
  &-side-icons {
    .form-control {
      &:focus,
      &:focus:hover {
        @extend %form-control-focus;
      }
    }

    .form-control-outline {
      &:focus,
      &:focus:hover {
        background: $form-input-outline-bg-focus;
        border-color: $form-input-outline-bc-focus;
      }
    }
  }
}

.form-control {
  &:hover {
    @extend %form-control-hover;
  }

  &:focus,
  &:focus:hover {
    @extend %form-control-focus;
  }

  &:disabled,
  &.disabled {
    @extend %form-control-disabled;

    &::placeholder {
      color: $form-input-placeholder-color-disabled;
    }

    ~ .f-i {
      color: $form-icon-color-disabled;
    }
  }

  &-outline {
    background: $form-input-outline-bg;
    border-color: $form-input-outline-bc;

    &:hover {
      @extend %form-control-outline-hover;
    }

    &:focus,
    &:focus:hover {
      background: $form-input-outline-bg-focus;
      border-color: $form-input-outline-bc-focus;
    }

    &:disabled,
    &.disabled {
      @extend %form-control-outline-disabled;

      &::placeholder {
        color: $form-input-outline-placeholder-color-disabled;
      }
    }
  }

  &-rounded {
    border-radius: $form-input-group-rounded-radius;
  }
}

.form-label {
  &-top {
    color: $form-label-color;
  }
  &-left {
    color: $form-label-color;
  }
}
