@import 'firefly/styles/mixin';
@import './datetime-variables';
@import './datetime-mixin';

.k-dateinput {
  .f-i-calendar {
    color: $form-icon-color;
  }

  &.is-invalid {
    .f-i-calendar {
      color: $form-invalid-color;
    }
  }

  &.is-warning {
    .f-i-calendar {
      color: $form-warning-color;
    }
  }
}

kendo-daterange,
kendo-datepicker[fDatePicker],
kendo-timepicker[fTimePicker] {
  .k-dateinput {
    border-top-left-radius: $border-radius-1;
    border-bottom-left-radius: $border-radius-1;

    &::selection {
      background-color: $color-accent-3-200;
      color: $color-neutral-700;
    }
  }
}

.k-datepicker,
.k-timepicker {
  .f-i-close {
    &:focus-visible {
      box-shadow: $shadow-focused-level-1-properties rgba($color-primary-600, $shadow-focused-level-1-opacity);
      border-radius: $border-radius-1;
    }
  }
}

.k-datepicker,
.k-timepicker {
  .k-button {
    color: $form-icon-color;
  }

  .k-picker-wrap,
  .k-dateinput-wrap {
    background-color: $color-base;
    border-color: $color-default-400;

    &.k-state-focused {
      background-color: $color-base;
      border-color: $color-default-600;
    }
  }

  .k-select,
  .k-select.k-state-hover,
  .k-select:hover {
    color: $color-neutral-400;
  }
}

.k-reset-button {
  > .f-i {
    color: $form-icon-color;

    &:hover {
      color: $form-icon-color-hover;
    }

    &:focus-visible {
      box-shadow: $shadow-focused-level-1-properties
        rgba($k-reset-button-focus-visible-shadow-color, $shadow-focused-level-1-opacity);
      border-radius: $border-radius-1;
      outline: none;
    }
  }
}

.k-cell-disabled {
  color: $text-muted-color;

  &:hover {
    background-color: $k-calendar-content-bg;
  }
}

.k-time-list-wrapper {
  &.k-focus {
    &::before {
      background: $k-time-list-focused-background;

      @include mobile-phones-only {
        background: $k-time-list-focused-background-mobile;
      }
    }

    &::after {
      background: $k-time-list-focused-background;

      @include mobile-phones-only {
        background: $k-time-list-focused-background-mobile;
      }
    }
  }
}

.k-time-list {
  &::before,
  &::after {
    box-shadow: $k-time-list-box-shadow;

    @include mobile-phones-only {
      box-shadow: $k-time-list-box-shadow-mobile;
    }
  }

  .k-item {
    &:hover {
      color: $k-time-list-item-hover;
    }
  }
}

.k-timeselector {
  border-radius: $border-radius-2;

  .k-time-accept {
    background-color: $btn-primary-bg;

    &:hover {
      background-color: $btn-primary-bg-hover;
    }
  }

  .k-time-cancel {
    color: $btn-secondary-color;

    &:hover {
      color: $btn-secondary-color-hover;
    }
  }
}

@include mobile-phones-only {
  .k-daterangepicker.k-daterangepicker-mobile-sm[fDateRangePicker].k-collapsed.with-reset-btn {
    .empty.k-dateinput:nth-of-type(1) {
      &::after {
        content: '';
        color: $input-placeholder-color;
      }

      .k-input-inner {
        padding-right: $k-dateinput-mobile-empty-input-with-reset-btn-pr;
      }
    }
  }
}

.k-daterangepicker:not(.f-is-collapsed) .k-dateinput,
.k-datepicker,
.k-timepicker {
  @include date-time-validation-state('invalid');
  @include date-time-validation-state('warning');
}

.k-daterangepicker.f-is-collapsed {
  @include date-time-validation-state('invalid', true);
  @include date-time-validation-state('warning', true);

  &.is-invalid {
    .k-dateinput {
      @extend .is-invalid;
    }
  }

  &.is-warning {
    .k-dateinput {
      @extend .is-warning;
    }
  }
}

.k-dateinput,
.k-datepicker,
.k-timepicker {
  &.is-invalid {
    background-color: $form-invalid-bg;
    border-color: $form-invalid-bc;

    .k-input-button {
      color: $form-invalid-color;
    }
  }

  &.is-warning {
    background-color: $form-warning-bg;
    border-color: $form-warning-bc;

    .k-input-button {
      color: $form-warning-color;
    }
  }
}
