@import 'firefly/styles/mixin';

$border-radius-map: (
  1: $border-radius-1,
  2: $border-radius-2,
  3: $border-radius-3,
  4: $border-radius-4
);

$border-radius-corners-map: (
  top-left: (
    1: $border-radius-1,
    2: $border-radius-2,
    3: $border-radius-3,
    4: $border-radius-4
  ),
  top-right: (
    1: $border-radius-1,
    2: $border-radius-2,
    3: $border-radius-3,
    4: $border-radius-4
  ),
  bottom-left: (
    1: $border-radius-1,
    2: $border-radius-2,
    3: $border-radius-3,
    4: $border-radius-4
  ),
  bottom-right: (
    1: $border-radius-1,
    2: $border-radius-2,
    3: $border-radius-3,
    4: $border-radius-4
  )
);

@each $border_radius_type, $sizes in $border-radius-corners-map {
  @each $size_number, $border_value in $sizes {
    .rounded-#{$border_radius_type}-#{$size_number} {
      @include enable-important('border-#{$border-radius-type}-radius', $border_value);
    }
  }
}
