$list-focus-shadow-color: null !default;
$list-focus-shadow: null !default;
$list-group-item-secondary-text-color: null !default;
$list-group-item-icon-color: null !default;
$list-group-item-hover-bg: null !default;
$list-group-item-active-disabled-color: null !default;
$list-group-item-active-disabled-bg: null !default;
$list-group-remove-icon-hover-color: null !default;
$list-item-with-background-bg: null !default;
$list-group-icon-disabled-opacity: null !default;

$list-item-selectable-bg: null !default;
$list-item-selectable-bc: null !default;
$list-item-selectable-bg-hover: null !default;
$list-item-selectable-bc-hover: null !default;
$list-item-selectable-selected-bg: null !default;
$list-item-selectable-selected-bc: null !default;

$f-list-item-border-color: null !default;
$f-list-item-border-color-focus: rgba($f-list-item-border-color, $shadow-focused-level-1-opacity);
$f-list-item-bg-focus: $list-group-item-hover-bg;

$list-dragging-item-active-bg: null !default;
$list-dragging-item-active-bs: null !default;
$list-dragging-active-icon-color: null !default;
$list-dragging-item-start-border-color: null !default;
$list-dragging-item-new-border-color: null !default;
$list-dragging-item-new-bg: null !default;

$toggle-list-item-bg-hover: null !default;
$toggle-list-item-checked-bg: null !default;
$toggle-list-item-icon-check-color: null !default;
$draggable-list-item-drag-icon-disabled-color: null !default;

$list-item-danger-color: null !default;
