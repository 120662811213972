$chip-bg: $color-default-100;
$chip-hover-color: $color-neutral-700;
$chip-hover-bg: $color-default-200;
$chip-focus-bg: $color-default-100;
$chip-focus-bs: $shadow-focused-level-1-properties rgba($color-primary-400, $shadow-focused-level-1-opacity);
$chip-active-bg: $color-default-400;
$chip-disabled-bg: rgba($color-default-100, 0.3);
$chip-disabled-color: rgba($color-neutral-700, 0.3);
$chip-disabled-icon-opacity: 0.3;
$chip-filter-bg: $color-default-300;
$chip-input-bg: $color-default-100;
$chip-input-active-bg: $color-default-200;
$chip-input-active-bc: $color-default-600;
$chip-input-hover-bg: $color-default-50;
$chip-input-hover-bc: $color-default-500;
$chip-input-pressed-bg: $color-default-300;
$chip-input-border-color: $color-default-600;
$chip-input-disabled-bg: $color-default-50;
$chip-input-disabled-color: rgba($color-neutral-700, 0.3);
$chip-input-disabled-border-color: $color-default-300;
$chip-input-disabled-icon-opacity: 0.3;
$chip-input-icon-color: $color-neutral-500;
$chip-input-icon-hover-color: $color-neutral-700;
$chip-input-secondary-bg: $color-default-900;
$chip-input-secondary-color: $color-base;
$chip-input-secondary-icon-color: $color-neutral-50;
$chip-input-secondary-icon-hover-color: $chip-input-secondary-color;
$chip-input-secondary-disabled-icon-color: $color-default-50;
$chip-input-secondary-disabled-icon-opacity: 0.7;
$chip-input-secondary-hover-bg: $color-default-700;
$chip-input-secondary-active-bg: $color-default-800;
$chip-input-secondary-pressed-bg: $color-neutral-900;
$chip-input-secondary-disabled-bg: $color-default-600;
$chip-input-secondary-disabled-color: rgba($color-base, 0.7);
$chip-input-danger-bg: $color-danger-400;
$chip-input-danger-color: $color-base;
$chip-input-danger-hover-bg: $color-danger-300;
$chip-input-danger-active-bg: $color-danger-600;
$chip-input-danger-active-bs: none;
$chip-input-danger-disabled-bg: $color-danger-200;
$chip-input-danger-focus-bg: $color-danger-400;
$chip-input-danger-focus-bs: $shadow-focused-level-1-properties rgba($color-danger-400, $shadow-focused-level-1-opacity);
$chip-icon-padding: 0.25rem;
$chip-choice-color: $color-neutral-700;
$chip-choice-bg: $color-default-300;
$chip-choice-border-color: $color-neutral-500;
$chip-choice-hover-color: $color-neutral-400;
$chip-choice-disabled-bg: $color-default-100;
$chip-choice-disabled-color: rgba($color-neutral-700, 0.3);
$chip-outline-bg: transparent;
$chip-outline-bd-color: $color-neutral-200;
$chip-outline-hover-bd-color: $color-neutral-300;
$chip-outline-active-bd-color: $color-neutral-400;
$chip-outline-disabled-bd-color: $color-neutral-100;
$chip-outline-active-disabled-bd-color: rgba($color-neutral-400, 0.3);
$chip-outline-disabled-bg: transparent;
$chip-outline-disabled-color: rgba($color-neutral-400, 0.3);
$chip-outline-active-disabled-color: rgba($color-neutral-700, 0.3);
$chip-info-icon-color: $color-neutral-400;
$chip-outline-icon-disabled-color: $color-neutral-400;
