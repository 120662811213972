$dropdown-spacer: 0.25rem;
$dropdown-padding-y: 0;
$dropdown-color: $body-color;
$dropdown-link-color: $body-color;
$dropdown-item-padding-x: 0.75rem;
$dropdown-item-padding-y: 0.375rem;
$dropdown-min-width: 140px;
$dropdown-link-hover-color: $body-color;
$dropdown-link-active-color: $body-color;

$caret-vertical-align: 0.1rem;
$caret-width: 0.25rem;
