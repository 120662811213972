
/**
* @tokens Assets
*/
$assets-path: "./../../../../../assets/general/";

/**
* @tokens Borders
* @presenter Border
*/
$border-style-solid: solid;
$border-style-dashed: dashed;
$border-width-1: 1px;
$border-width-2: 2px;

/**
* @tokens Border-radii
*/
$border-radius-1: 2px;
$border-radius-2: 3px;
$border-radius-3: 10px;
$border-radius-4: 5px;
$border-radius-100: 50%;

/**
* @tokens Grid-breakpoints
*/
$grid-breakpoint-xs: 360px;
$grid-breakpoint-sm: 600px;
$grid-breakpoint-md: 876px;
$grid-breakpoint-lg: 1024px;
$grid-breakpoint-xl: 1280px;
$grid-breakpoint-xxl: 1536px;
$grid-breakpoint-xxxl: 1920px;

/**
* @tokens Container-max-widths
*/
$container-max-width-xs: 328px;
$container-max-width-sm: 552px;
$container-max-width-md: 828px;
$container-max-width-lg: 976px;
$container-max-width-xl: 1232px;
$container-max-width-xxl: 1488px;
$container-max-width-xxxl: 1872px;

/**
* @tokens Fonts
*/
$font-base-size: 0.875rem;
$font-base-line-height: 1.25rem;
$font-heading-70-size: 2rem;
$font-heading-70-line-height: 3rem;
$font-heading-50-size: 1.5rem;
$font-heading-50-line-height: 2rem;
$font-heading-40-size: 1.375rem;
$font-heading-40-line-height: 2rem;
$font-heading-30-size: 1.125rem;
$font-heading-30-line-height: 1.75rem;
$font-heading-20-size: 0.75rem;
$font-heading-20-line-height: 1rem;
$font-heading-70-size-mobile: 1.375rem;
$font-heading-70-line-height-mobile: 1.65rem;
$font-heading-50-size-mobile: 1.125rem;
$font-heading-50-line-height-mobile: 1.5rem;
$font-heading-40-size-mobile: 1.125rem;
$font-heading-40-line-height-mobile: 1.5rem;
$font-heading-30-size-mobile: 1rem;
$font-heading-30-line-height-mobile: 1.26315rem;
$font-subtitle-50-size: 1rem;
$font-subtitle-50-line-height: 1.5rem;
$font-subtitle-50-size-mobile: 1rem;
$font-subtitle-50-line-height-mobile: 1.26315rem;
$font-body-70-size: 0.875rem;
$font-body-70-line-height: 1.25rem;
$font-body-50-size: 0.75rem;
$font-body-50-line-height: 1rem;
$font-body-40-size: 0.6875rem;
$font-body-40-line-height: 0.875rem;
$font-body-30-size: 0.625rem;
$font-body-30-line-height: 0.75rem;
$font-link-70-size: 0.875rem;
$font-link-70-line-height: 1.25rem;
$font-link-50-size: 0.75rem;
$font-link-50-line-height: 1rem;
$font-icon-xxs: 0.75rem;
$font-icon-xs: 1rem;
$font-icon-sm: 1.25rem;
$font-icon-md: 1.5rem;
$font-icon-lg: 1.875rem;
$font-icon-xl: 2.1875rem;
$font-icon-xxl: 2.5rem;
$font-weight-normal: 400;
$font-weight-bold: 600;
$font-family: "Akkurat", sans-serif;

/**
* @tokens Zindices
*/
$zindex-mobile-inner-error: 1150;
$zindex-toast: 1090;
$zindex-confirmation-modal: 1065;
$zindex-onesearch: 1055;
$zindex-top-navigation: 990;
$zindex-onesearch-backdrop: 980;
$zindex-left-navigation: 970;
$zindex-drawer: 960;
$zindex-suggester: 955;
$zindex-drawer-collapsed: 950;
$zindex-sub-navigation: 940;

/**
* @tokens Brand-assets-paths
*/
$brand-assets-path: "./../../../../../../assets/brandings/snp-light/";

/**
* @tokens Brand-assets-common-paths
*/
$brand-assets-common-path: "./../../../../../../assets/brandings/common/light/";

/**
* @tokens Brand-icon-assets-paths
*/
$brand-icon-assets-path: "./../../../../../../assets/brandings/common/light/icons/";

/**
* @tokens Brand-icon-layouts-assets-paths
*/
$brand-icon-layouts-assets-path: "./../../../../../assets/brandings/common/light/icons/";

/**
* @tokens Colors
* @presenter Color
*/
$color-primary-50: #f6fdff;
$color-primary-100: #ebf9fc;
$color-primary-200: #d3eef5;
$color-primary-300: #aedae5;
$color-primary-400: #66a7b8;
$color-primary-500: #338ba1;
$color-primary-600: #006d89;
$color-primary-700: #005d75;
$color-primary-800: #005063;
$color-primary-900: #004759;
$color-primary-950: #004152;
$color-neutral-50: #f5f5f5;
$color-neutral-100: #e6e6e6;
$color-neutral-200: #cececf;
$color-neutral-300: #adadad;
$color-neutral-400: #999999;
$color-neutral-500: #6d6d6d;
$color-neutral-600: #383838;
$color-neutral-700: #333333;
$color-neutral-800: #292929;
$color-neutral-900: #1a1a1a;
$color-success-50: #f7faf7;
$color-success-100: #eef5f0;
$color-success-200: #deefe1;
$color-success-300: #bedec4;
$color-success-400: #9dcea6;
$color-success-500: #7dbe88;
$color-success-600: #5cae6a;
$color-success-700: #3c9d4d;
$color-success-800: #167527;
$color-success-900: #125e1f;
$color-warning-50: #fffaeb;
$color-warning-100: #fff7e3;
$color-warning-200: #fff3d4;
$color-warning-300: #fcecc0;
$color-warning-400: #fadf96;
$color-warning-500: #f2cb6f;
$color-warning-600: #f0c15d;
$color-warning-700: #e9b350;
$color-warning-800: #d59f3c;
$color-warning-900: #c18b28;
$color-warning-950: #9d5700;
$color-danger-50: #fff6f9;
$color-danger-100: #ffeff3;
$color-danger-200: #fadde6;
$color-danger-300: #f3a7c3;
$color-danger-400: #df6b95;
$color-danger-500: #d83159;
$color-danger-600: #d10f49;
$color-danger-700: #b20040;
$color-danger-800: #960036;
$color-danger-900: #880129;
$color-default-50: #f8f8f8;
$color-default-100: #f5f5f5;
$color-default-200: #eeeeee;
$color-default-300: #e2e2e2;
$color-default-400: #c2c2c2;
$color-default-500: #888888;
$color-default-600: #6b6b6b;
$color-default-700: #5c5c5c;
$color-default-800: #494844;
$color-default-900: #1f1f1f;
$color-accent-1-50: #fcf4f6;
$color-accent-1-100: #fbeff2;
$color-accent-1-200: #f5dfe6;
$color-accent-1-300: #ebbfcd;
$color-accent-1-400: #e19fb4;
$color-accent-1-500: #cd6083;
$color-accent-1-600: #c3406a;
$color-accent-1-700: #a31a46;
$color-accent-1-800: #8a183c;
$color-accent-1-900: #7b1536;
$color-accent-2-50: #fbf6fc;
$color-accent-2-100: #f7eef8;
$color-accent-2-200: #f1e4f2;
$color-accent-2-300: #e6d2e9;
$color-accent-2-400: #d7b8da;
$color-accent-2-500: #b280b6;
$color-accent-2-600: #8b4092;
$color-accent-2-700: #73287a;
$color-accent-2-800: #641b6b;
$color-accent-2-900: #501555;
$color-accent-3-50: #f2fbfe;
$color-accent-3-100: #e9f8fd;
$color-accent-3-200: #dceff9;
$color-accent-3-300: #b4d5e9;
$color-accent-3-400: #85b7d4;
$color-accent-3-500: #6598b5;
$color-accent-3-600: #507e99;
$color-accent-3-700: #416a82;
$color-accent-3-800: #345569;
$color-accent-3-900: #284454;
$color-accent-4-50: #fef7ef;
$color-accent-4-100: #fdefde;
$color-accent-4-200: #fceddb;
$color-accent-4-300: #fadbb6;
$color-accent-4-400: #f7c992;
$color-accent-4-500: #f4b86d;
$color-accent-4-600: #ec8200;
$color-accent-4-700: #c56c00;
$color-accent-4-800: #9d5700;
$color-accent-4-900: #764100;
$color-accent-5-50: #f2fdfb;
$color-accent-5-100: #f0fcf9;
$color-accent-5-200: #e9f5f4;
$color-accent-5-300: #c6eae1;
$color-accent-5-400: #31ac8d;
$color-accent-5-500: #0f9e7a;
$color-accent-5-600: #0c8466;
$color-accent-5-700: #13725a;
$color-accent-5-800: #0a6951;
$color-accent-5-900: #084f3d;
$color-accent-6-50: #eef0f8;
$color-accent-6-100: #e3e6f4;
$color-accent-6-200: #c0c8e7;
$color-accent-6-300: #aab5df;
$color-accent-6-400: #7c8fce;
$color-accent-6-500: #566cbf;
$color-accent-6-600: #495cb2;
$color-accent-6-700: #3e51ad;
$color-accent-6-800: #2c3991;
$color-accent-6-900: #01145f;
$color-base: #ffffff;
$color-brand: #d6002a;

/**
* @tokens Shadows
* @presenter Shadow
*/
$shadow-neutral-900-level-0-properties: 0 0 2px 0;
$shadow-neutral-900-level-0-opacity: 0.12;
$shadow-neutral-900-level-1-properties: 0 1px 5px 0;
$shadow-neutral-900-level-1-opacity: 0.08;
$shadow-neutral-900-level-2-properties: 0 1px 3px 0;
$shadow-neutral-900-level-2-opacity: 0.22;
$shadow-neutral-900-level-3-properties: 0 2px 5px 0;
$shadow-neutral-900-level-3-opacity: 0.16;
$shadow-neutral-900-level-4-properties: 0 2px 7px 0;
$shadow-neutral-900-level-4-opacity: 0.14;
$shadow-neutral-900-level-5-properties: 0 2px 10px 0;
$shadow-neutral-900-level-5-opacity: 0.14;
$shadow-neutral-900-level-6-properties: 0 4px 8px 0;
$shadow-neutral-900-level-6-opacity: 0.07;
$shadow-neutral-900-level-6-top-properties: 0 -4px 8px 0;
$shadow-neutral-900-level-6-top-opacity: 0.07;
$shadow-neutral-900-level-6-left-properties: -4px 0 8px 0;
$shadow-neutral-900-level-6-left-opacity: 0.07;
$shadow-neutral-900-level-6-right-properties: 4px 0 8px 0;
$shadow-neutral-900-level-6-right-opacity: 0.07;
$shadow-neutral-900-level-7-properties: 0 8px 50px 0;
$shadow-neutral-900-level-7-opacity: 0.08;
$shadow-primary-700-level-1-properties: 0 1px 5px 0;
$shadow-primary-700-level-1-opacity: 0.08;
$shadow-primary-700-level-2-properties: 0 1px 3px 0;
$shadow-primary-700-level-2-opacity: 0.36;
$shadow-primary-700-level-3-properties: 0 2px 5px 0;
$shadow-primary-700-level-3-opacity: 0.26;
$shadow-primary-700-level-4-properties: 0 2px 7px 0;
$shadow-primary-700-level-4-opacity: 0.2;
$shadow-primary-700-level-5-properties: 0 2px 10px 0;
$shadow-primary-700-level-5-opacity: 0.2;
$shadow-primary-700-level-6-properties: 0 8px 16px 0;
$shadow-primary-700-level-6-opacity: 0.15;
$shadow-primary-700-level-7-properties: 0 8px 50px 0;
$shadow-primary-700-level-7-opacity: 0.08;
$shadow-focused-level-1-properties: 0 0 0 2px;
$shadow-focused-level-1-opacity: 0.75;
$shadow-inner-neutral-900-level-1-properties: 0 2px 3px 0;
$shadow-inner-neutral-900-level-1-opacity: 0.06;
$shadow-inner-neutral-900-level-2-properties: 0 2px 3px 0;
$shadow-inner-neutral-900-level-2-opacity: 0.22;