$axis-label-color: null !default;
$axis-domain-color: null !default;
$axis-zero-line-color: null !default;
$rect-bar-fill: null !default;
$rect-bar-selected-color: null !default;
$chart-rect-border-color: null !default;
$line-stroke: null !default;
$area-fill: null !default;
$area-fill-opacity: null !default;
$area-stroke: null !default;
$area-pointer-line-stroke: null !default;
$area-pointer-line-stroke-selected: null !default;
$svg-donut-chart-arc-fill-1: null !default;
$svg-donut-chart-arc-fill-2: null !default;
$svg-donut-chart-arc-fill-3: null !default;
$bar-halo-color: null !default;
$part-donut-empty-arc-bg: null !default;
$stripes-pattern-color-1: null !default;
$stripes-pattern-color-2: null !default;
$stripes-mask-color: null !default;
$f-stacked-bar-basis-bg: null !default;
$non-selected-bar-opacity: 1;
$non-selected-bar-saturate: null !default;
$non-selected-bar-brightness: null !default;
$non-selected-stacked-bar-brightness: null !default;
$non-selected-stacked-bar-opacity: null !default;
$grouped-bar-fill-1: null !default;
$grouped-bar-fill-2: null !default;
$grouped-bar-fill-3: null !default;
$grouped-bar-fill-4: null !default;
$grouped-bar-fill-5: null !default;
$grouped-bar-fill-6: null !default;
$scale-chart-bar-bg-5: null !default;
$positive-value-bar-color: null !default;
$negative-value-bar-color: null !default;

@mixin non-selected-bar-filters($saturate, $brightness, $opacity) {
  filter: saturate($saturate) brightness($brightness) opacity($opacity);

  @include safari-only {
    opacity: 0.75;
  }
}

@mixin bar-series-filters {
  .interactive:not(.selected) {
    .f-bubble-wrapper,
    .f-rect-bar {
      @include non-selected-bar-filters(
        $non-selected-bar-saturate,
        $non-selected-bar-brightness,
        $non-selected-bar-opacity
      );
    }
  }
}

@mixin grouped-bar-series-filters {
  .interactive:not(.selected) [f-bar-series],
  .interactive:not(.selected) [f-positive-negative-bar-series] {
    @include non-selected-bar-filters(
      $non-selected-bar-saturate,
      $non-selected-bar-brightness,
      $non-selected-bar-opacity
    );
  }
}

@mixin stacked-bar-series-filters {
  .interactive:not(.selected) {
    .f-bubble-wrapper,
    .f-rect-bar {
      @include non-selected-bar-filters(
        $non-selected-bar-saturate,
        $non-selected-stacked-bar-brightness,
        $non-selected-stacked-bar-opacity
      );
    }
  }
}

$concentration-bar-highlighted-decile: null !default;
$concentration-bar-decile-bubble: null !default;
$concentration-bar-chart-color-scheme-0-level-1: null !default;
$concentration-bar-chart-color-scheme-0-level-2: null !default;
$concentration-bar-chart-color-scheme-0-level-3: null !default;
$concentration-bar-chart-color-scheme-0-level-4: null !default;
$concentration-bar-chart-color-scheme-0-level-5: null !default;
$concentration-bar-chart-color-scheme-1-level-1: null !default;
$concentration-bar-chart-color-scheme-1-level-2: null !default;
$concentration-bar-chart-color-scheme-1-level-3: null !default;
$concentration-bar-chart-color-scheme-1-level-4: null !default;
$concentration-bar-chart-color-scheme-1-level-5: null !default;

$concentration-bar-chart-color-map: (
  scheme-0: (
    level-1: $concentration-bar-chart-color-scheme-0-level-1,
    level-2: $concentration-bar-chart-color-scheme-0-level-2,
    level-3: $concentration-bar-chart-color-scheme-0-level-3,
    level-4: $concentration-bar-chart-color-scheme-0-level-4,
    level-5: $concentration-bar-chart-color-scheme-0-level-5
  ),
  scheme-1: (
    level-1: $concentration-bar-chart-color-scheme-1-level-1,
    level-2: $concentration-bar-chart-color-scheme-1-level-2,
    level-3: $concentration-bar-chart-color-scheme-1-level-3,
    level-4: $concentration-bar-chart-color-scheme-1-level-4,
    level-5: $concentration-bar-chart-color-scheme-1-level-5
  )
);

@mixin concentration-bar-chart-color($concentration-bar-chart) {
  @each $type, $type-map in $concentration-bar-chart {
    @each $level, $color in $type-map {
      .concentration-#{$type}-#{$level} {
        fill: $color;
        background-color: $color;
      }
    }
  }

  @each $type, $type-map in $concentration-bar-chart {
    .concentration-#{$type}-gradient {
      background: linear-gradient(
        to right,
        map-get($concentration-bar-chart, $type, 'level-1') 20%,
        map-get($concentration-bar-chart, $type, 'level-2') 20% 40%,
        map-get($concentration-bar-chart, $type, 'level-3') 40% 60%,
        map-get($concentration-bar-chart, $type, 'level-4') 60% 80%,
        map-get($concentration-bar-chart, $type, 'level-5') 80%
      );
    }
  }
}
