@import 'firefly/styles/functions';

$shadow-neutral-900-props-map: (
  1: (
    prop: $shadow-neutral-900-level-1-properties,
    opacity: $shadow-neutral-900-level-1-opacity
  ),
  2: (
    prop: $shadow-neutral-900-level-2-properties,
    opacity: $shadow-neutral-900-level-2-opacity
  ),
  3: (
    prop: $shadow-neutral-900-level-3-properties,
    opacity: $shadow-neutral-900-level-3-opacity
  ),
  4: (
    prop: $shadow-neutral-900-level-4-properties,
    opacity: $shadow-neutral-900-level-4-opacity
  ),
  5: (
    prop: $shadow-neutral-900-level-5-properties,
    opacity: $shadow-neutral-900-level-5-opacity
  ),
  6: (
    prop: $shadow-neutral-900-level-6-properties,
    opacity: $shadow-neutral-900-level-6-opacity
  ),
  7: (
    prop: $shadow-neutral-900-level-7-properties,
    opacity: $shadow-neutral-900-level-7-opacity
  )
);

$shadow-primary-700-props-map: (
  1: (
    prop: $shadow-primary-700-level-1-properties,
    opacity: $shadow-primary-700-level-1-opacity
  ),
  2: (
    prop: $shadow-primary-700-level-2-properties,
    opacity: $shadow-primary-700-level-2-opacity
  ),
  3: (
    prop: $shadow-primary-700-level-3-properties,
    opacity: $shadow-primary-700-level-3-opacity
  ),
  4: (
    prop: $shadow-primary-700-level-4-properties,
    opacity: $shadow-primary-700-level-4-opacity
  ),
  5: (
    prop: $shadow-primary-700-level-5-properties,
    opacity: $shadow-primary-700-level-5-opacity
  ),
  6: (
    prop: $shadow-primary-700-level-6-properties,
    opacity: $shadow-primary-700-level-6-opacity
  ),
  7: (
    prop: $shadow-primary-700-level-7-properties,
    opacity: $shadow-primary-700-level-7-opacity
  )
);

$shadow-inner-neutral-900-props-map: (
  1: (
    prop: $shadow-inner-neutral-900-level-1-properties,
    opacity: $shadow-inner-neutral-900-level-1-opacity
  ),
  2: (
    prop: $shadow-inner-neutral-900-level-2-properties,
    opacity: $shadow-inner-neutral-900-level-2-opacity
  )
);

$shadow-neutral-900-level-6-directions-map: (
  top: (
    prop: $shadow-neutral-900-level-6-top-properties,
    opacity: $shadow-neutral-900-level-6-top-opacity
  ),
  left: (
    prop: $shadow-neutral-900-level-6-left-properties,
    opacity: $shadow-neutral-900-level-6-left-opacity
  ),
  right: (
    prop: $shadow-neutral-900-level-6-right-properties,
    opacity: $shadow-neutral-900-level-6-right-opacity
  )
);

@each $shadow-level, $prop, $opacity in $shadow-neutral-900-props-map {
  $shadow-props: map-deep-get($shadow-neutral-900-props-map, $shadow-level, 'prop');
  $shadow-opacity: map-deep-get($shadow-neutral-900-props-map, $shadow-level, 'opacity');
  $shadow-color: rgba($color-neutral-900, $shadow-opacity);

  .shadow-neutral-#{$shadow-level} {
    box-shadow: $shadow-props $shadow-color !important;
  }
}

@each $shadow-level, $prop, $opacity in $shadow-primary-700-props-map {
  $shadow-props: map-deep-get($shadow-primary-700-props-map, $shadow-level, 'prop');
  $shadow-opacity: map-deep-get($shadow-primary-700-props-map, $shadow-level, 'opacity');
  $shadow-color: rgba($color-primary-700, $shadow-opacity);

  .shadow-primary-#{$shadow-level} {
    box-shadow: $shadow-props $shadow-color !important;
  }
}

@each $shadow-inner-level, $prop, $opacity in $shadow-inner-neutral-900-props-map {
  $shadow-inner-props: map-deep-get($shadow-inner-neutral-900-props-map, $shadow-inner-level, 'prop');
  $shadow-inner-opacity: map-deep-get($shadow-inner-neutral-900-props-map, $shadow-inner-level, 'opacity');
  $shadow-inner-color: rgba($color-neutral-900, $shadow-inner-opacity);

  .inner-shadow-neutral-#{$shadow-inner-level} {
    box-shadow: inset $shadow-inner-props $shadow-inner-color !important;
  }
}

@each $shadow-direction, $prop, $opacity in $shadow-neutral-900-level-6-directions-map {
  $shadow-direction-props: map-deep-get($shadow-neutral-900-level-6-directions-map, $shadow-direction, 'prop');
  $shadow-direction-opacity: map-deep-get($shadow-neutral-900-level-6-directions-map, $shadow-direction, 'opacity');
  $shadow-color: rgba($color-neutral-900, $shadow-direction-opacity);

  .shadow-neutral-6-#{$shadow-direction} {
    box-shadow: $shadow-direction-props $shadow-color !important;
  }
}
