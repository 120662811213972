@import './buttons_variables';

.k-toolbar .k-button-solid-base.k-selected:focus {
  box-shadow: $kendo-button-focus-shadow;
}

.k-toolbar .k-toolbar-overflow-button {
  background-color: $btn-secondary-bg !important;
  color: $btn-secondary-color;

  &[aria-expanded='true'] {
    background-color: $btn-primary-bg !important;
    color: $btn-primary-color !important;

    &:hover {
      background-color: $btn-primary-bg !important;
      color: $btn-primary-color !important;
    }
  }

  &::after {
    transition: none;
  }

  &:hover {
    background-color: $btn-secondary-bg-hover !important;
    color: $btn-secondary-color-hover;
  }

  &:active,
  &:active:focus {
    background-color: $btn-secondary-bg-pressed !important;
    color: $btn-secondary-color-pressed;
  }

  &.k-focus::after,
  &:focus::after {
    box-shadow: $btn-secondary-bs-focus;
    opacity: 1;
  }
}

.k-button-group {
  .k-button:focus {
    z-index: $toolbar-button-focus-z-index;
  }

  .k-button:first-child {
    border-top-left-radius: $border-radius-1;
    border-bottom-left-radius: $border-radius-1;
  }
  .k-button:last-child {
    border-top-right-radius: $border-radius-1;
    border-bottom-right-radius: $border-radius-1;
  }
}

.k-toolbar .k-overflow-anchor {
  border-radius: $border-radius-2;
}
