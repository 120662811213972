@import './../../../kendo-styles/components/editor/editor-variables';
@import './../../../kendo-styles/components/list/list-variables';

.fr-box.fr-basic {
  @extend .k-editor;
  border-radius: 0;
}

.fr-toolbar {
  font-family: $font-family;
}

.fr-toolbar.fr-basic {
  border-color: $kendo-toolbar-border;
  color: $kendo-toolbar-text;
  background-color: $kendo-toolbar-bg;
}

.fr-toolbar.fr-basic .fr-btn-grp {
  @extend .k-button-group;
}

.fr-box.fr-basic.fr-top div.fr-wrapper {
  background-color: $editor-background-color;
  border-color: $kendo-toolbar-border;
}

.fr-toolbar.fr-basic .fr-command.fr-btn {
  &:not(.fr-dropdown) {
    @extend .k-button;
  }

  &.fr-command {
    @extend .k-button-solid-base;
  }
}

.fr-toolbar.fr-basic .fr-command.fr-dropdown {
  @extend .k-picker, .k-picker-solid;

  &::after {
    border-radius: $border-radius-1;
  }
}

.fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-active {
  &:hover {
    background-color: $kendo-picker-hover-bg;
  }
}

.fr-toolbar.fr-basic .fr-command.fr-btn.fr-disabled {
  color: $editor-clear-icon-color;
}

.fr-box.fr-basic .fr-element {
  color: $editor-text-color;
}

.f-text-editor-validation.fr-box.fr-basic.fr-top .fr-wrapper {
  background-color: $editor-validation-bg;
  border-color: $editor-validation-bc;
}

.fr-btn-active-popup,
.fr-toolbar.fr-basic .fr-command.fr-dropdown.fr-active {
  box-shadow: $kendo-picker-focus-shadow;
}

.fr-active:not(.fr-dropdown) {
  @extend .k-selected;
}

.fr-popup {
  background-color: $k-animation-container-popup-bg;
  box-shadow: $k-animation-container-popup-bs;
  border-color: $k-animation-container-popup-border-color;
}

.fr-popup .fr-input-line input + label,
.fr-popup .fr-input-line input.fr-not-empty + label {
  @extend .form-label-top;
  background-color: $k-animation-container-popup-bg;
}

.fr-popup .fr-input-line input[type='text'] {
  @extend .form-control, .form-control-outline;
}

.fr-popup .fr-layer.fr-color-hex-layer .fr-action-buttons button.fr-command {
  @extend .btn, .btn-primary;
}

.fr-command.fr-btn + .fr-dropdown-menu {
  background-color: $dropdown-bg;

  .fr-command:hover {
    background-color: $dropdown-bg;
  }
}

.fr-command.fr-btn.fr-active + .fr-dropdown-menu {
  border-radius: $border-radius-1;
}

.fr-command.fr-btn + .fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list li a.fr-active {
  background-color: $dropdown-bg;
  border-width: $border-width-2;
  border-color: rgba($color-primary-600, $k-list-item-selected-opacity);
  border-radius: $border-radius-1;
}

.fr-toolbar.fr-basic .fr-btn-grp .fr-btn-wrap .fr-command.fr-btn:first-child:not(.fr-dropdown) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.fr-toolbar.fr-basic .fr-btn-grp .fr-command.fr-btn.fr-active:not(.fr-dropdown) {
  box-shadow: none;
}

.fr-command.fr-btn + .fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list li a svg path,
.fr-popup .fr-color-set > span > svg path {
  fill: $list-group-color;
}

.fr-popup .fr-color-set > .fr-command[data-cmd='applybackgroundColor']:has(.fr-svg):not(.fr-table-cell),
.fr-color-set > .fr-command[data-cmd='applytextColor']:has(.fr-svg):not(.fr-table-cell) {
  @extend .btn, .btn-primary, .btn-icon;
}

.fr-toolbar .fr-command.fr-btn.fr-dropdown,
.fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-active {
  &::after {
    border-top-color: $kendo-picker-text;
  }
}

.fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-active:hover::after {
  border-top-color: $list-group-color;
}

.fr-popup {
  border-radius: $border-radius-2;
}

.fr-popup .fr-action-buttons button.fr-command {
  @extend .btn, .btn-primary;
}

.fr-popup .fr-checkbox input {
  @extend .form-check-input;
}

.fr-command.fr-btn.fr-active + .fr-dropdown-menu {
  box-shadow: $dropdown-box-shadow;
}

.fr-contextual-menu {
  @extend .dropdown-menu;
}

.fr-tooltip {
  background-color: $tooltip-bg;
  color: $tooltip-color;
}
