@import './skeleton-mixins';
@import './skeleton-variables';

.k-skeleton-light {
  background-color: $skeleton-light-bg;
}

.k-skeleton-dark {
  background-color: $skeleton-dark-bg;
}

.k-skeleton-wave.k-skeleton::after,
.k-skeleton-wave .k-skeleton::after {
  @include skeleton-bg-gradient(0.5);
}

.k-skeleton-light.k-skeleton-wave.k-skeleton::after,
.k-skeleton-light.k-skeleton-wave .k-skeleton::after {
  @include skeleton-bg-gradient(0.7);
}

f-skeleton-table-loader .k-grid .k-checkbox kendo-skeleton {
  border-radius: $border-radius-1;
}
