@import './list-variables';

.k-list {
  .k-item:focus.k-state-selected,
  .k-item.k-state-focused.k-state-selected {
    box-shadow: $k-list-item-bs-focus;
  }

  .k-list-item.k-selected {
    border-color: rgba($color-primary-600, $k-list-item-selected-opacity);
    border-radius: $border-radius-1;

    &:hover {
      background-color: $kendo-list-item-selected-bg;
    }
  }
}

.k-list-optionlabel.k-selected {
  border-color: rgba($color-primary-600, $k-list-item-selected-opacity);
  border-radius: $border-radius-1;
}

.k-list-optionlabel.k-state-focused.k-state-selected {
  background-color: $color-base;
}
