@import './table-mixins';
@import './table-variables';

.k-grid {
  tbody {
    tr {
      background-color: $grid-body-row-bg;
      &.k-grouping-table-row {
        background-color: $k-grouping-table-row;

        &:hover {
          background-color: $k-grouping-table-row-hover;
        }
      }
    }
  }

  .k-detail-cell {
    &:hover {
      background-color: $color-base;
    }

    .k-grid-header.k-table-thead:has(.k-grid-header-sticky):has(.k-grid-detail-header)
      + tbody
      tr:first-child
      td:not(.k-checkbox-column) {
      border-top-color: $k-grid-border-color;
    }

    .k-grid-header,
    .k-grid-header-sticky {
      background-color: $kendo-grid-bg;
      color: $text-muted-color;

      .k-table-row:hover,
      .k-header:hover {
        background-color: transparent;
      }

      .k-header {
        border-bottom-color: $k-grid-border-color;
      }
    }

    .k-grid-aria-root tr td:first-of-type.k-table-td.k-grid-content-sticky {
      border-right-color: $k-grid-border-color;
    }

    .k-footer-template:hover {
      background-color: $k-footer-template-hover;
    }
  }

  tr:only-child td {
    border-bottom-color: $k-grid-border-color;
  }

  &.k-selectable-grid {
    &:not(.k-ignored-cell-clicked) {
      //to avoid cell hover and focus behind ignored cell
      td {
        &:hover {
          box-shadow: $k-cell-shadow-properties $k-cell-shadow-color-hover;
          background-color: $k-cell-bg-hover;
          border-radius: $border-radius-1;
        }

        &.k-focus {
          box-shadow: $k-cell-focused-state-shadow;
          border-radius: $border-radius-1;
        }
      }
    }

    td {
      &:hover:active {
        box-shadow: $k-cell-shadow-properties $k-cell-shadow-color-active;
      }

      &.k-selected {
        box-shadow: $k-cell-shadow-properties $k-cell-shadow-color-selected;
        border-radius: $border-radius-1;

        &:hover {
          box-shadow: $k-cell-shadow-properties $k-cell-shadow-color-selected-hover;
          background-color: $k-cell-bg-hover;

          &:active {
            box-shadow: $k-cell-shadow-properties $k-cell-shadow-color-selected-active;
          }
        }

        &.k-focus {
          &:active {
            box-shadow: $k-cell-shadow-properties $k-cell-shadow-color-selected-focus-active,
              $k-cell-focused-state-shadow;
          }
        }
      }
    }

    &.k-context-menu-is-open {
      .k-selectable-cell.k-selected,
      .k-selected:hover {
        box-shadow: $k-cell-shadow-properties $k-cell-shadow-color-selected;

        &.ca-cell-with-note,
        &.ca-activity-cell-auto-mark,
        &.ca-activity-cell-with-note {
          @include cell-marker-color($k-context-menu-open-marker-bg);
        }
      }
    }
  }

  a {
    @extend .link;

    &.link-primary {
      @extend .link-primary;
    }

    &.link-success {
      @extend .link-success;
    }

    &.link-warning {
      @extend .link-warning;
    }

    &.link-danger {
      @extend .link-danger;
    }

    &.link-main {
      @extend .link-main;
    }

    &.link-secondary {
      @extend .link-secondary;
    }
  }

  .k-grid-content-locked,
  .k-grid-content-locked td {
    border-bottom-color: $color-neutral-100;
  }

  .k-grid-content-locked {
    box-shadow: $grid-locked-shadow-props $grid-locked-shadow-color;

    .k-state-hover td,
    tr:hover td {
      background-color: $color-default-100;
    }
  }

  .k-header:hover {
    background-color: $k-header-hover-bg;

    @include mobile-phones-only {
      background-color: transparent;
    }

    .k-column-resizer {
      border-left-color: $k-column-resizer-color;
    }
  }
}

.k-grid-with-summary-row .k-grid-footer {
  border-color: $k-grid-border-color;
}

.k-grid.k-selectable-grid.k-context-menu-is-open .k-master-row .k-table-td.k-selectable-cell.k-selected {
  &:not(.k-grid-content-sticky) {
    background-color: $k-context-menu-open-cell-bg;
  }

  &.k-grid-content-sticky {
    background-color: $k-context-menu-open-sticky-cell-bg;
  }
}

.k-grid.k-selectable-grid .k-master-row > .k-table-td.k-focus {
  box-shadow: $k-cell-focused-state-shadow;
}

.k-grid.k-selectable-grid .k-master-row td.k-selected.k-focus {
  box-shadow: $k-cell-shadow-properties $color-primary-500, $k-cell-focused-state-shadow;
}

.k-grid-header:has(f-mobile-table-header) {
  padding-right: 0 !important; // to override inline styles
}

.k-grid-header .k-header .k-column-resizer {
  &:hover::before {
    background-color: $color-primary-600;
  }
}

.k-drag-clue {
  background-color: $k-drag-clue-bg;
  border-color: $k-drag-clue-border-color;
  color: $k-drag-clue-color;
  @extend .shadow-neutral-3;
}

.k-grouping-dropclue {
  &::before {
    border-right-color: $color-primary-500;
  }
}

.k-grid-footer {
  td {
    border-top-color: $kendo-grid-border;
  }
}

.k-grid-with-summary-row {
  &.k-selectable-grid {
    .k-footer-template {
      td:hover,
      td:hover:active {
        @include ignore-cell-selection-color($k-grid-footer-bg);
      }

      td.k-footer-highlighted-cell,
      td.k-footer-highlighted-cell:hover,
      td.k-footer-highlighted-cell:hover:active {
        background-color: $k-footer-highlighted-cell-bg;
      }
    }
  }

  .k-grid-footer {
    background-color: $k-grid-footer-bg;
  }

  .k-grid-footer-wrap {
    color: $body-color;

    td {
      &:not(:first-child) .k-footer-cell-value {
        &:after {
          border-bottom-color: $kendo-grid-sticky-border;
        }
      }
    }
  }

  .k-footer-highlighted-cell {
    background-color: $k-footer-highlighted-cell-bg;
  }
}

.k-menu-popup {
  .k-context-menu {
    .k-menu-item > .k-menu-link {
      color: $body-color;

      &:hover {
        background-color: $k-context-menu-item-bg;
      }
    }

    .k-item:focus {
      box-shadow: $k-cell-focused-state-shadow;
      background-color: $k-context-menu-item-bg;
      border-radius: $border-radius-1;
    }

    .k-item.k-state-disabled {
      &:focus {
        background-color: unset;
      }
    }
  }
}

.k-grid.k-selectable-grid {
  tr:hover,
  tr:hover .k-selected {
    .ca-cell-with-note {
      background-color: $cell-with-note-hover-bg;
    }

    .ca-activity-cell,
    .ca-activity-cell-with-note,
    .ca-activity-cell-auto-mark {
      background-color: $cell-activity-hover-bg;
    }
  }
}
.k-grid td {
  &.ca-cell-with-note {
    @include cell-with-marker-color($cell-with-note-bg, $cell-with-note-marker-bg);
  }

  &.ca-activity-cell {
    background-color: $cell-activity-bg;
  }

  &.ca-activity-cell-auto-mark {
    @include cell-with-marker-color($cell-activity-auto-mark-bg, $cell-activity-auto-mark-bg-marker);
  }

  &.ca-activity-cell-with-note {
    @include cell-with-marker-color($cell-activity-bg, $cell-activity-with-note-marker);
  }

  &.k-selected.k-selectable-cell {
    &.ca-cell-with-note {
      @include selected-cell-styles($cell-with-note-bg, $cell-with-note-hover-bg);
    }

    &.ca-activity-cell,
    &.ca-activity-cell-with-note {
      @include selected-cell-styles($cell-activity-bg, $cell-activity-hover-bg);
    }

    &.ca-activity-cell-auto-mark {
      @include selected-cell-styles($cell-activity-auto-mark-bg, $cell-activity-hover-bg);
    }
  }
}

.k-grid tr:hover > .k-highlighted-column {
  background-color: $kendo-grid-highlighted-column-hovered-bg;
}

.k-highlighted-column {
  background-color: $kendo-grid-highlighted-column-bg;
}

.k-grid {
  .k-table-row {
    &.k-highlighted-row,
    &.k-highlighted-collapsed-row {
      &:not(.k-selected) .k-table-td:not(.k-highlighted-column) {
        background-color: $k-highlighted-row-cell-bg;
      }

      &:not(:hover) .k-grid-content-sticky:not(.k-checkbox-column:empty) {
        .k-grid &,
        .k-grid .k-detail-row:hover & {
          background-color: $k-highlighted-row-cell-bg;
        }
      }

      f-mobile-expanded-row & > div:not(.k-grid-detail-header) {
        background-color: $k-highlighted-row-cell-bg;
      }
    }

    &.k-highlighted-collapsed-row {
      &:not(.k-selected) .k-table-td:not(.k-highlighted-column) {
        background-color: $k-highlighted-collapsed-row-cell-bg;
      }

      &:not(:hover) .k-grid-content-sticky:not(.k-checkbox-column:empty) {
        .k-grid &,
        .k-grid .k-detail-row:hover & {
          background-color: $k-highlighted-collapsed-row-cell-bg;
        }
      }

      f-mobile-expanded-row & > div:not(.k-grid-detail-header) {
        background-color: $k-highlighted-collapsed-row-cell-bg;
      }
    }
  }

  @include desktops-only {
    @media (min-width: $grid-breakpoint-sm) {
      .k-table-row.k-highlighted-row:not(.k-selected):hover .k-table-td:not(.k-highlighted-column),
      .k-table-row.k-highlighted-collapsed-row:not(.k-selected):hover .k-table-td:not(.k-highlighted-column) {
        background-color: $k-cell-bg-hover;
      }
    }
  }
}
// background-color for interactive cells should be the same for all types of rows
.k-interactive-cell {
  &:hover {
    background-color: $k-interactive-cell-bg !important;

    @include mobile-phones-only {
      background-color: transparent !important;
    }
  }

  &.k-interactive-cell-selected {
    background-color: $k-interactive-cell-selected-bg !important;

    @include mobile-phones-only {
      background-color: transparent !important;
    }
  }
}

f-mobile-expanded-row .k-interactive-cell-marker,
.k-interactive .k-interactive-cell-marker,
tr:hover .k-interactive-cell-marker,
.k-interactive-cell-selected.k-interactive-cell-marker {
  &::after {
    border-top-color: $k-interactive-cell-selected-marker-color;
  }
}

kendo-grid.k-grid .k-table-row:hover {
  .k-grid-content-sticky {
    background-color: $kendo-grid-bg;

    &.k-selected {
      background-color: $kendo-grid-sticky-hover-bg;
    }
  }

  > .k-grid-content-sticky {
    background-color: $kendo-grid-sticky-hover-bg;

    @include mobile-phones-only {
      background-color: transparent;
    }
  }
}

kendo-grid.k-grid .k-table-row.k-grid-row-sticky {
  & {
    .k-table-td {
      background-color: $k-grid-sticky-bg;
    }

    @include desktops-only {
      @media (min-width: $grid-breakpoint-sm) {
        // this media is specified for proper behavior in storybook
        &:hover .k-table-td {
          background-color: $k-cell-bg-hover;
        }
      }
    }
  }

  &.k-selected {
    &,
    &:hover {
      .k-table-td {
        background-color: $kendo-grid-selected-bg;
      }
    }
  }
}

@include mobile-phones-only {
  .k-grid-detail-header {
    color: $text-muted-color;
    font-size: $font-body-50-size;
    line-height: $font-body-50-line-height;
  }
}
