@import './dropdown-variables';

.dropdown {
  &-menu {
    .nested-dropdown {
      &.dropdown-item-checkbox.show {
        background-color: $dropdown-item-bg-focus;

        &:hover {
          background-color: $dropdown-nested-item-hover-bg;
        }
      }
    }
  }

  &-item:focus-visible,
  &-item.list-group-item:focus-visible {
    border-color: $dropdown-item-border-color-focus;
    border-radius: $border-radius-1;
  }

  @at-root {
    .list-group:not(.list-group-borderless) {
      .dropdown-item:focus-visible {
        border-color: $dropdown-item-border-color-focus;
      }
    }
  }

  .sub-menu-open {
    color: $sub-menu-icon-color;
  }

  .cascade-menu-close-icon {
    &:hover {
      color: $cascade-menu-close-icon-color-hover;
    }
  }

  .btn-with-initials {
    @extend .body-50;
    color: $btn-with-initials-color;
  }
}

@media (hover: hover) {
  .dropdown-item:not(.disabled) {
    &:hover {
      background-color: $dropdown-item-bg-hover;
    }
  }
}
