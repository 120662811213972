$kendo-scheduler-text: $body-color;

$kendo-scheduler-event-text: $body-color;
$kendo-scheduler-event-selected-text: $body-color;
$kendo-scheduler-event-bg: transparent;
$kendo-scheduler-event-selected-bg: transparent;
$kendo-scheduler-event-selected-shadow: none;
$kendo-cell-padding: 2px;
$kendo-scheduler-cell-padding-y: 2px;
$kendo-scheduler-othermonth-bg: transparent;
