@import './progress-variables';

.progress {
  &-error {
    background-color: $progress-error-bg;

    &-info {
      color: $color-danger-700;
    }
  }

  &-action {
    @extend .link, .link-primary;
  }

  &-overlay {
    &::before {
      background-color: rgba($progress-overlay-color, $progress-overlay-opacity);
    }
  }
}
