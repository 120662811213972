$input-focus-border-color: $color-default-600;

$kendo-input-hover-bg: $form-input-bg-hover;
$kendo-input-hover-border: $form-input-bc-hover;
$kendo-input-hover-shadow: $form-input-bs-hover;
$kendo-input-focus-bg: $form-input-bg-focus;
$kendo-input-focus-border: $form-input-bc-focus;
$kendo-input-focus-shadow: $form-input-bs-focus;
$k-input-solid-focus-color: $color-neutral-700;

$kendo-input-outline-bg: $form-input-outline-bg;
$kendo-input-outline-border: $form-input-outline-bc;
$kendo-input-outline-hover-bg: $form-input-outline-bg-hover;
$kendo-input-outline-hover-border: $form-input-outline-bc-hover;
$kendo-input-outline-focus-bg: $form-input-outline-bg-focus;
$kendo-input-outline-focus-border: $form-input-outline-bc-focus;

$kendo-picker-hover-bg: $btn-secondary-bg-hover;
$kendo-picker-focus-bg: $btn-secondary-bg-focus;
$kendo-picker-focus-shadow: $shadow-focused-level-1-properties rgba($color-primary-400, $shadow-focused-level-1-opacity);

$kendo-input-border: $input-border-color;
$kendo-input-text: $input-color;
$kendo-input-bg: $input-bg;
$kendo-input-placeholder-text: $input-placeholder-color;
