@import './../../../mixin';

$disabled-color-base: rgba($color-base, 0.7) !default;
$focus-link-shadow-opacity: 1;

$link-primary-color: $color-primary-700 !default;
$link-primary-color-hover: $color-primary-800 !default;
$link-primary-color-active: $color-primary-900 !default;
$link-primary-color-focus: $color-primary-700 !default;
$link-primary-color-disabled: $color-primary-300 !default;

$link-main-color: $color-neutral-700 !default;
$link-main-color-hover: $color-primary-700 !default;
$link-main-color-active: $color-primary-800 !default;
$link-main-color-focus: $color-neutral-700 !default;
$link-main-color-disabled: $color-neutral-300 !default;

$link-secondary-color: $color-neutral-400 !default;
$link-secondary-color-hover: $color-primary-600 !default;
$link-secondary-color-active: $color-primary-700 !default;
$link-secondary-color-focus: $color-neutral-400 !default;
$link-secondary-color-disabled: $color-neutral-200 !default;

$link-primary-shadow-color-focus: $color-primary-400 !default;
$link-main-shadow-color-focus: $color-primary-400 !default;
$link-secondary-shadow-color-focus: $color-primary-400 !default;
$link-success-shadow-color-focus: $color-success-600 !default;
$link-warning-shadow-color-focus: $color-warning-600 !default;
$link-danger-shadow-color-focus: $color-danger-500 !default;
$text-main-color: $color-neutral-700 !default;
$text-heading-20-color: $color-neutral-500 !default;

$search-highlight-bg-color: null !default;

@mixin disabled-link($color) {
  color: $color;
  box-shadow: none;
  text-decoration: none;
  cursor: not-allowed;
}

@mixin focus-visible-styles($shadow-color, $color, $shadow-focus) {
  border-radius: $border-radius-1;
  box-shadow: inset $shadow-focused-level-1-properties rgba($shadow-color, $shadow-focus);
  color: $color;
}

$typography-config: (
  heading: (
    70: (
      fs: $font-heading-70-size,
      lh: $font-heading-70-line-height
    ),
    50: (
      fs: $font-heading-50-size,
      lh: $font-heading-50-line-height
    ),
    40: (
      fs: $font-heading-40-size,
      lh: $font-heading-40-line-height
    ),
    30: (
      fs: $font-heading-30-size,
      lh: $font-heading-30-line-height
    ),
    20: (
      fs: $font-heading-20-size,
      lh: $font-heading-20-line-height
    )
  ),
  subtitle: (
    50: (
      fs: $font-subtitle-50-size,
      lh: $font-subtitle-50-line-height
    )
  ),
  body: (
    70: (
      fs: $font-body-70-size,
      lh: $font-body-70-line-height
    ),
    50: (
      fs: $font-body-50-size,
      lh: $font-body-50-line-height
    ),
    40: (
      fs: $font-body-40-size,
      lh: $font-body-40-line-height
    ),
    30: (
      fs: $font-body-30-size,
      lh: $font-body-30-line-height
    )
  ),
  link: (
    70: (
      fs: $font-link-70-size,
      lh: $font-link-70-line-height
    ),
    50: (
      fs: $font-link-50-size,
      lh: $font-link-50-line-height
    )
  )
);

$link-colors: (
  primary: (
    'color': $link-primary-color,
    'color-hover': $link-primary-color-hover,
    'color-focus': $link-primary-color-focus,
    'color-active': $link-primary-color-active,
    'shadow-color-focus': $link-primary-shadow-color-focus
  ),
  main: (
    'color': $link-main-color,
    'color-hover': $link-main-color-hover,
    'color-focus': $link-main-color-focus,
    'color-active': $link-main-color-active,
    'shadow-color-focus': $link-main-shadow-color-focus
  ),
  secondary: (
    'color': $link-secondary-color,
    'color-hover': $link-secondary-color-hover,
    'color-focus': $link-secondary-color-focus,
    'color-active': $link-secondary-color-active,
    'shadow-color-focus': $link-secondary-shadow-color-focus
  ),
  success: (
    'color': $color-success-700,
    'color-hover': $color-success-500,
    'color-focus': $color-success-700,
    'color-active': $color-success-600,
    'shadow-color-focus': $link-success-shadow-color-focus
  ),
  warning: (
    'color': $color-warning-700,
    'color-hover': $color-warning-500,
    'color-focus': $color-warning-700,
    'color-active': $color-warning-600,
    'shadow-color-focus': $link-warning-shadow-color-focus
  ),
  danger: (
    'color': $color-danger-700,
    'color-hover': $color-danger-500,
    'color-focus': $color-danger-700,
    'shadow-active': $color-danger-600,
    'shadow-color-focus': $link-danger-shadow-color-focus
  ),
  base: (
    'color': $color-base,
    'color-hover': $color-base,
    'color-focus': $color-base,
    'shadow-active': $color-base,
    'shadow-color-focus': $color-base
  )
);

$disabled-link-color: (
  main: $link-main-color-disabled,
  secondary: $link-secondary-color-disabled,
  primary: $link-primary-color-disabled,
  success: $color-success-300,
  warning: $color-warning-400,
  danger: $color-danger-300,
  base: $disabled-color-base
);

@each $name, $modifier in $typography-config {
  @each $level, $size, $lh in $modifier {
    $font-size: map-deep-get($typography-config, $name, $level, 'fs');
    $line-height: map-deep-get($typography-config, $name, $level, 'lh');
    $color: $text-main-color;
    $font-weight: null;
    $text-transform: null;

    @if $name == 'heading' {
      $font-weight: $font-weight-bold;

      @if $level == 20 {
        $color: $text-heading-20-color;
        $text-transform: uppercase;
      }
    }

    @if $name == 'link' {
      $color: null;
    }

    .#{$name}-#{$level} {
      font-size: $font-size;
      line-height: $line-height;
      color: $color;
      font-weight: $font-weight;
      text-transform: $text-transform;
    }
  }
}

$typography-config-mobile: (
  heading: (
    70: (
      fs: $font-heading-70-size-mobile,
      lh: $font-heading-70-line-height-mobile
    ),
    50: (
      fs: $font-heading-50-size-mobile,
      lh: $font-heading-50-line-height-mobile
    ),
    40: (
      fs: $font-heading-40-size-mobile,
      lh: $font-heading-40-line-height-mobile
    ),
    30: (
      fs: $font-heading-30-size-mobile,
      lh: $font-heading-30-line-height-mobile
    )
  ),
  subtitle: (
    50: (
      fs: $font-subtitle-50-size-mobile,
      lh: $font-subtitle-50-line-height-mobile
    )
  )
);

@each $name, $modifier in $typography-config-mobile {
  @each $level, $size, $lh in $modifier {
    $font-size: map-deep-get($typography-config-mobile, $name, $level, 'fs');
    $line-height: map-deep-get($typography-config-mobile, $name, $level, 'lh');

    @include mobile-phones-only {
      .increased-baseline-size .#{$name}-#{$level} {
        font-size: $font-size;
        line-height: $line-height;
      }
    }
  }
}

@each $color, $value in $link-colors {
  $hover-link-color: map-deep-get($link-colors, $color, 'color-hover');
  $active-link-color: map-deep-get($link-colors, $color, 'color-active');
  $focus-link-color: map-deep-get($link-colors, $color, 'color-focus');
  $focus-link-shadow-color: map-deep-get($link-colors, $color, 'shadow-color-focus');

  .link-#{$color} {
    color: map-deep-get($link-colors, $color, 'color');

    &:hover {
      text-decoration: $link-hover-decoration;
    }

    &:hover {
      color: $hover-link-color;
    }

    &:focus-visible {
      @if $color == base {
        @include focus-visible-styles($focus-link-shadow-color, $focus-link-color, $focus-link-shadow-opacity);
      } @else {
        @include focus-visible-styles($focus-link-shadow-color, $focus-link-color, $shadow-focused-level-1-opacity);
      }
    }

    &:active {
      color: $active-link-color;
    }

    &:disabled,
    &[disabled],
    &.disabled,
    &.link-disabled {
      @include disabled-link(map-deep-get($disabled-link-color, $color));
    }
  }
}

.link {
  cursor: pointer;

  &:focus {
    outline: none;
  }

  @include mobile-phones-only {
    &:hover {
      text-decoration: none;
    }
  }
}

a:disabled,
a[disabled],
a.disabled,
a.link-disabled {
  @include disabled-link($color-default-500);
}

.text-muted {
  color: $text-muted-color !important;
}

.search-highlight {
  background-color: $search-highlight-bg-color;
}
