@import 'common-variables';

.f-grid-line,
.f-axis-domain {
  stroke: $axis-domain-color;
}

.f-tick,
.f-charts-axis-label {
  fill: $axis-label-color;
}

.f-tick-line {
  stroke: $color-neutral-100;
}

.f-axis-zero-line {
  stroke: $axis-zero-line-color;
}
