.k-numerictextbox .k-button-solid-base {
  background-color: $numerictextbox-button-bg;
  color: $numerictextbox-button-color;

  &:hover {
    background-color: $numerictextbox-button-bg-hover;
  }
}

.k-numerictextbox.is-invalid {
  background-color: $form-invalid-bg;
  border-color: $form-invalid-bc;
  box-shadow: $form-invalid-bs;

  &:hover {
    @extend %is-invalid-hover;
  }

  &.k-focus,
  &.k-focus:hover {
    @extend %is-invalid-focus;
  }
}

.k-numerictextbox.k-input-outline.is-invalid {
  background-color: $form-invalid-outline-bg !important; // to override !important in kendo styles

  &:hover {
    background-color: $form-valid-outline-bg-hover !important; // to override !important in kendo styles
  }

  &.k-focus,
  &.k-focus:hover {
    background-color: $form-valid-outline-bg-focus !important; // to override !important in kendo styles
  }
}
