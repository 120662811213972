@import './toolbar-variables';
@import '../../../bootstrap-styles/components/dropdown/dropdown-variables';

.k-menu-group .k-item {
  > .k-link {
    border-radius: $border-radius-1;

    &.k-selected {
      background-color: $dropdown-item-bg-focus;
    }
  }

  &:not(.k-disabled):focus > .k-link {
    box-shadow: inset 0 0 0 $border-width-2 $dropdown-item-border-color-focus;
  }

  &.k-disabled {
    color: $list-group-disabled-color;
  }
}

.k-toolbar .k-toolbar-overflow-button:focus::after {
  box-shadow: 0 0 0 $border-width-2 $dropdown-item-border-color-focus;
}

.k-animation-container.k-list .k-list-item.k-selected {
  background-color: $dropdown-item-bg-focus;
}
