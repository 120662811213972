@import 'spinner-variables';

.spinner {
  &-border {
    color: $spinner-color;
  }

  &-overlay {
    &::before {
      background-color: rgba($spinner-overlay-color, $spinner-overlay-opacity);
    }
  }
}
