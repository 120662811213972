$disabled-color-default-200: null !default;
$disabled-color-neutral-500: null !default;
$disabled-color-base: null !default;

$btn-color-transparent: null !default;

$btn-primary-bg: null !default;
$btn-primary-color: null !default;
$btn-primary-bg-hover: null !default;
$btn-primary-color-hover: null !default;
$btn-primary-bs-focus: null !default;
$btn-primary-bg-pressed: null !default;
$btn-primary-color-pressed: null !default;
$btn-primary-color-focus: null !default;
$btn-primary-bg-disabled: null !default;
$btn-primary-color-disabled: null !default;

$btn-secondary-bg: null !default;
$btn-secondary-color: null !default;
$btn-secondary-bg-hover: null !default;
$btn-secondary-color-hover: null !default;
$btn-secondary-bg-pressed: null !default;
$btn-secondary-bg-focus: null !default;
$btn-secondary-bs-focus: null !default;
$btn-secondary-color-pressed: null !default;
$btn-secondary-color-focus: null !default;
$btn-secondary-bg-disabled: null !default;
$btn-secondary-color-disabled: null !default;

$btn-warning-bg: null !default;
$btn-warning-color: null !default;
$btn-warning-bg-hover: null !default;
$btn-warning-color-hover: null !default;
$btn-warning-bg-pressed: null !default;
$btn-warning-color-pressed: null !default;
$btn-warning-color-focus: null !default;
$btn-warning-bs-focus: null !default;
$btn-warning-bg-disabled: null !default;
$btn-warning-color-disabled: null !default;

$btn-outline-bg: null !default;
$btn-outline-color: $text-muted-color;
$btn-outline-color-hover: $text-muted-color;
$btn-outline-color-pressed: $body-color;
$btn-outline-color-disabled: null !default;
$btn-outline-color-pressed-disabled: null !default;
$btn-outline-bd-color: null !default;
$btn-outline-bd-color-hover: null !default;
$btn-outline-bd-color-pressed: null !default;
$btn-outline-bd-color-disabled: null !default;
$btn-outline-bg-disabled: null !default;

$btn-danger-bg: null !default;
$btn-danger-color: null !default;
$btn-danger-bg-hover: null !default;
$btn-danger-color-hover: null !default;
$btn-danger-bg-pressed: null !default;
$btn-danger-color-pressed: null !default;
$btn-danger-color-focus: null !default;
$btn-danger-bs-focus: null !default;
$btn-danger-bg-disabled: null !default;
$btn-danger-color-disabled: null !default;

$btn-link-bg-hover: null !default;
$btn-link-box-shadow: null !default;
$btn-link-bg-pressed: null !default;

$btn-icon-primary-bs-focus: null !default;

$btn-icon-danger-bg: null !default;
$btn-icon-danger-color: null !default;
$btn-icon-danger-bg-hover: null !default;
$btn-icon-danger-color-hover: null !default;
$btn-icon-danger-color-active: null !default;
$btn-icon-danger-color-focus: null !default;
$btn-icon-danger-bg-focus: null !default;
$btn-icon-danger-bg-focus-visible: null !default;
$btn-icon-danger-box-shadow-focus-visible: null !default;
$btn-icon-danger-bg-pressed: null !default;
$btn-icon-danger-bg-disabled: null !default;
$btn-icon-danger-color-disabled: null !default;

$btn-simple-icon-primary-color: null !default;
$btn-simple-icon-primary-color-hover: null !default;
$btn-simple-icon-primary-bg-focus: null !default;
$btn-simple-icon-primary-color-focus: null !default;
$btn-simple-icon-primary-color-pressed: null !default;
$btn-simple-icon-primary-color-disabled: null !default;

$btn-simple-icon-secondary-color: null !default;
$btn-simple-icon-secondary-color-hover: null !default;
$btn-simple-icon-secondary-color-disabled: null !default;
$btn-simple-icon-secondary-color-active: null !default;
$btn-simple-icon-secondary-color-focus: null !default;
$btn-simple-icon-secondary-bg-focus: null !default;
$btn-simple-icon-bs-focus: null !default;

$btn-simple-icon-bg-active: null !default;
$btn-simple-icon-color-active: null !default;
$btn-simple-icon-opacity: null !default;

$btn-simple-icon-danger-bg: null !default;
$btn-simple-icon-danger-color: null !default;
$btn-simple-icon-danger-color-hover: null !default;
$btn-simple-icon-danger-color-active: null !default;
$btn-simple-icon-danger-bg-focus: null !default;
$btn-simple-icon-danger-bg-focus-visible: null !default;
$btn-simple-icon-danger-box-shadow-focus-visible: null !default;
$btn-simple-icon-danger-bg-pressed: null !default;
$btn-simple-icon-danger-color-disabled: null !default;

$btn-group-inner-border-radius: 1px;
