$form-input-bg-opacity-disabled: 0.5;
$form-input-opacity-disabled: 0.3;
$form-input-shadow-normal: inset $shadow-inner-neutral-900-level-1-properties
  rgba($color-neutral-900, $shadow-inner-neutral-900-level-1-opacity);
$form-icon-color: $color-neutral-400;
$form-icon-color-hover: $color-neutral-700;
$form-icon-color-disabled: rgba($color-neutral-400, 0.3);

$form-active-icon-color-hover: $color-primary-700;

$form-input-bg-hover: $color-default-100;
$form-input-bc-hover: $color-default-100;
$form-input-bs-hover: $form-input-shadow-normal;

$form-input-bg-focus: $color-default-50;
$form-input-bc-focus: $color-primary-500;
$form-input-bs-focus: $form-input-shadow-normal;

$form-input-bg-disabled: rgba($color-default-200, $form-input-bg-opacity-disabled);
$form-input-bc-disabled: rgba($color-default-200, $form-input-bg-opacity-disabled);
$form-input-color-disabled: rgba($color-neutral-700, $form-input-bg-opacity-disabled);
$form-input-placeholder-color-disabled: rgba($color-neutral-700, $form-textarea-disabled-text-opacity);

$form-label-color: $color-neutral-500;
$form-label-top-margin-bottom: 0.25rem;

$form-input-outline-bc: $color-default-400;
$form-input-outline-bg: transparent;
$form-input-outline-bc-hover: $color-default-500;
$form-input-outline-bg-hover: transparent;
$form-input-outline-bc-focus: $color-primary-500;
$form-input-outline-bg-focus: $form-input-outline-bg;
$form-input-outline-bc-disabled: $color-default-300;
$form-input-outline-bg-disabled: $form-input-outline-bg;
$form-input-outline-color-disabled: $form-input-color-disabled;
$form-input-outline-placeholder-color-disabled: $form-input-placeholder-color-disabled;

$form-control-hover-bg: $color-default-100;
$form-control-hover-bc: $color-default-100;
$form-control-hover-shadow: $form-input-shadow-normal;

$form-control-outline-hover-bc: $color-default-500;
