@mixin background-image-styles($option) {
  background-image: url('#{$brand-assets-common-path}' + '#{$option}.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
}

@mixin empty-state($caption-color, $info-color, $options) {
  &-caption {
    color: $caption-color;
  }

  &-info {
    color: $info-color;
  }

  &-img {
    @each $option in $options {
      &.#{$option} {
        @include background-image-styles($option);
      }

      @if $option == 'merge-simple' {
        &.merge {
          @include background-image-styles('merge-simple');
        }
      }
    }
  }
}
