@import './empty-states-mixins';
@import './empty-states-variables';

.empty-state-note {
  @extend .body-50;
  color: $empty-state-note-color;
}

.onboarding-empty-state {
  @include empty-state($onboarding-caption-color, null, $onboarding-options);
}

.simple-actionable-empty-state {
  @include empty-state($simple-actionable-caption-color, $simple-actionable-info-color, $simple-actionable-options);
}

.simple-empty-state {
  @include empty-state($simple-caption-color, null, $simple-options);
}

.no-results-empty-state {
  &-img {
    background-image: url('#{$brand-assets-common-path}' + '#{$no-results-img}.svg');
    background-repeat: no-repeat;
    background-size: contain;
  }

  &-info {
    color: $no-results-info-color;
  }
}
