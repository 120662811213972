$btn-padding-x: 0.75rem;
$btn-padding-y: 0.375rem;
$btn-padding-y-lg: 0.625rem;

$btn-border-width: 0;

$btn-active-box-shadow: null;
$btn-box-shadow: null;
$btn-focus-width: 0;
$btn-focus-box-shadow: null;

$btn-font-size-sm: 0.75rem;
$btn-font-size-lg: 0.875rem;
$btn-font-weight: $font-weight-bold;

$btn-disabled-opacity: 1;
$btn-transition: none;

$btn-close-width: 16px;
$btn-close-height: 16px;
$btn-close-opacity: 1;
$btn-close-hover-opacity: 1;
$btn-close-focus-shadow: none;
