@import './icons-variables';

.k-i-arrow-60-left,
.k-i-arrow-60-right,
.k-i-calendar,
.k-i-clock,
.k-i-caret-alt-up,
.k-i-caret-alt-down,
.k-i-sort-asc-small,
.k-i-sort-desc-small,
.k-i-more-vertical {
  font-family: $k-icon-font-family;
}

.k-i-arrow-60-left,
.k-i-arrow-60-right {
  font-size: $k-i-arrow-60-font-size;
}

.k-i-arrow-60-left::before {
  @extend .f-i-chevron-left;
}

.k-i-arrow-60-right {
  @extend .f-i-chevron-right;
}

.k-i-calendar {
  font-size: $k-i-calendar-font-size;
  @extend .f-i-calendar;
}

.k-i-clock {
  font-size: $k-i-clock-font-size;
  @extend .f-i-clock;
}

.k-i-sort-asc-small {
  @extend .f-i-caret-up;
}

.k-i-sort-desc-small {
  @extend .f-i-caret-down;
}

.k-i-caret-alt-up {
  @extend .f-i-chevron-right;
  transform: rotate(-$k-i-arrow-rotate);
}

.k-i-caret-alt-down {
  @extend .f-i-chevron-right;
  transform: rotate($k-i-arrow-rotate);
}

.k-i-more-vertical {
  @extend .f-i-dots-y;
  font-size: $k-i-more-vertical-font-size;
}
