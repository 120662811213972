@mixin input-group-with-icon {
  position: relative;

  .input-wrapper .f-i,
  > .f-i {
    color: $form-icon-color;

    &.f-i-close {
      &:hover {
        color: $body-color;
      }
    }
  }
}
