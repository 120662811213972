@import './alerts-variables';

.alert {
  color: $body-color;

  &-dismissible {
    .btn-close {
      color: $alert-btn-close-color;
      &:hover {
        color: $alert-btn-close-hover-color;
      }
      &:focus {
        color: $alert-btn-close-hover-color;
      }
    }
  }

  &-info,
  &-caution {
    background-color: $alert-info-bg;
  }
  &-warning {
    background-color: $alert-warning-bg;
  }
  &-success {
    background-color: $alert-success-bg;
  }
  &-danger {
    background-color: $alert-danger-bg;
  }
  .f-i:not(.f-i-close) {
    &.f-i-info,
    &.f-i-caution {
      color: $alert-info-icon-color;
    }
    &.f-i-warning {
      color: $alert-warning-icon-color;
    }
    &.f-i-success {
      color: $alert-success-icon-color;
    }
    &.f-i-danger {
      color: $alert-danger-icon-color;
    }
  }
}
