@import 'loading-message-variables';

.loading-message-first-circle {
  fill: $loading-message-first-circle-fill;
}

.loading-message-second-circle {
  fill: $loading-message-second-circle-fill;
}

.loading-message-third-circle {
  fill: $loading-message-third-circle-fill;
}
