$list-focus-shadow-color: $color-primary-400;
$list-focus-shadow: $shadow-focused-level-1-properties rgba($list-focus-shadow-color, $shadow-focused-level-1-opacity);
$list-group-item-secondary-text-color: $color-neutral-400;
$list-group-item-icon-color: $color-neutral-400;
$list-group-item-hover-bg: $color-default-100;
$list-group-item-active-disabled-color: $color-neutral-300;
$list-group-item-active-disabled-bg: $color-default-200;
$list-group-remove-icon-hover-color: $color-neutral-700;
$list-item-with-background-bg: $color-default-100;
$list-group-icon-disabled-opacity: 0.3;

$list-item-selectable-bg: $color-default-100;
$list-item-selectable-bc: $color-default-100;
$list-item-selectable-bg-hover: $color-default-50;
$list-item-selectable-bc-hover: $color-default-400;
$list-item-selectable-selected-bg: $color-default-300;
$list-item-selectable-selected-bc: $color-default-300;

$f-list-item-border-color: $color-primary-400;

$list-dragging-item-active-bg: $color-base;
$list-dragging-item-active-bs: $shadow-neutral-900-level-6-properties
  rgba($color-neutral-900, $shadow-neutral-900-level-6-opacity);
$list-dragging-active-icon-color: $color-neutral-700;
$list-dragging-item-start-border-color: $color-neutral-200;
$list-dragging-item-new-border-color: $color-primary-700;
$list-dragging-item-new-bg: $color-primary-100;

$toggle-list-item-bg-hover: $color-default-100;
$toggle-list-item-checked-bg: $color-success-100;
$toggle-list-item-icon-check-color: $color-success-600;
$draggable-list-item-drag-icon-disabled-color: rgba($color-neutral-400, 0.3);
$list-item-danger-color: $color-danger-700;
