$form-check-label-cursor: pointer;
$form-check-input-bg: transparent;
$form-check-input-width: 1rem;
$form-check-input-focus-box-shadow: none;
$form-check-input-checked-bg-color: none;
$form-check-input-checked-bg-image: '';
$form-check-input-indeterminate-color: none;
$form-check-input-indeterminate-bg-color: none;
$form-check-input-indeterminate-border-color: none;
$form-check-input-indeterminate-bg-image: none;
$form-check-input-active-filter: none;
$form-check-padding-left: 0;
$form-check-min-height: auto;
$form-check-inline-margin-right: 0;
$form-switch-padding-left: 0;
$form-switch-bg-image: none;
$form-switch-width: 2rem;
$form-switch-focus-bg-image: '';
$form-switch-checked-bg-image: '';
$form-switch-checked-bg-position: unset;
$box-shadow-inset: none;
$form-check-label-disabled-opacity: 0.5;
