@import './chips-variables';
@import './chips-mixins';

.chip {
  border-color: transparent;
  border-radius: $border-radius-1;
  @include chip-base($c: $chip-color, $bg: $chip-bg);

  &:hover {
    @include chip-base($c: $chip-hover-color, $bg: $chip-hover-bg, $bc: transparent);
  }

  &:focus {
    @include chip-base($bc: transparent);
  }

  &:focus-visible,
  &.focus {
    @include chip-base($bs: $chip-focus-bs, $bc: transparent);
  }

  &:active,
  &:hover:active {
    @include chip-base($c: $chip-hover-color, $bg: $chip-active-bg, $bs: none);
  }

  &[disabled],
  &.disabled {
    @include chip-base($bg: $chip-disabled-bg, $c: $chip-disabled-color);

    &:hover,
    &:active,
    &:hover:active {
      @include chip-base($bg: $chip-disabled-bg, $c: $chip-disabled-color, $bs: none);
    }

    .f-i {
      opacity: $chip-disabled-icon-opacity;
    }
  }

  &-choice,
  &-choice:hover,
  &-choice:active,
  &-choice:hover:active,
  &-filter,
  &-input {
    color: $chip-choice-color;
  }

  &-choice,
  &-choice:hover,
  &-choice:active,
  &-choice:hover:active,
  &-filter {
    background-color: $chip-choice-bg;
  }

  &-choice {
    &[disabled],
    &.disabled {
      color: $chip-choice-disabled-color;
      background-color: $chip-choice-disabled-bg;

      &:hover,
      &:active,
      &:hover:active {
        background-color: $chip-choice-disabled-bg;
      }
    }
  }

  .f-i-info {
    color: $chip-info-icon-color;
  }

  &.chip-outline {
    background-color: $chip-outline-bg;
    border-color: $chip-outline-bd-color;
    color: $chip-outline-color;

    &:hover {
      background-color: $chip-outline-bg;
      color: $chip-outline-hover-color;
      border-color: $chip-outline-hover-bd-color;
    }

    &:active,
    &:hover:active {
      @include chip-base($c: $chip-outline-active-color, $bg: $chip-outline-bg, $bc: $chip-outline-active-bd-color);
    }

    &[disabled],
    &.disabled {
      @include chip-outline-disabled;

      .f-i {
        color: $chip-outline-icon-disabled-color;
      }

      &:hover,
      &:active,
      &:hover:active {
        @include chip-outline-disabled;
      }
    }

    &.chip-choice {
      border-color: $chip-choice-border-color;
      color: $chip-outline-active-color;

      &:active,
      &:hover:active {
        @include chip-base($c: $chip-outline-active-color, $bg: $chip-outline-bg, $bc: $chip-outline-active-bd-color);
      }

      &[disabled],
      &.disabled {
        color: $chip-outline-active-disabled-color;
        border-color: $chip-outline-active-disabled-bd-color;
        &:hover {
          color: $chip-outline-active-disabled-color;
          border-color: $chip-outline-active-disabled-bd-color;
        }
      }
    }
  }

  &-input {
    background-color: $chip-input-bg;
    border-color: $chip-input-border-color;

    &:hover,
    &:hover:focus-visible {
      @include chip-base($bg: $chip-input-hover-bg, $bc: $chip-input-hover-bc);
    }

    &:active,
    &:hover:active {
      @include chip-base($bg: $chip-input-pressed-bg, $bc: $chip-input-border-color);
    }

    &:focus {
      @include chip-base($bg: $chip-input-active-bg, $bc: $chip-input-active-bc);
    }

    &:active:focus {
      @include chip-base($bg: $chip-input-pressed-bg, $bc: $chip-input-border-color);
    }

    &:focus-visible {
      @include chip-base($bg: $chip-input-bg, $bs: $chip-focus-bs, $bc: $chip-input-border-color);
    }

    &.disabled,
    &.disabled:hover,
    &.disabled:active:focus {
      @include chip-base(
        $c: $chip-input-disabled-color,
        $bg: $chip-input-disabled-bg,
        $bc: $chip-input-disabled-border-color
      );

      .f-i {
        opacity: $chip-input-disabled-icon-opacity;
      }
    }

    .f-i {
      color: $chip-input-icon-color;
      &:hover {
        color: $chip-input-icon-hover-color;
      }
    }
  }

  &-input-secondary {
    @extend %chip-input-secondary;

    &:focus-visible {
      @include chip-base($bg: $chip-input-secondary-bg);
    }

    &[disabled],
    &.disabled,
    &.disabled:hover,
    &.disabled:active:focus {
      @include chip-base($bg: $chip-input-secondary-disabled-bg, $c: $chip-input-secondary-disabled-color);

      .f-i {
        color: $chip-input-secondary-disabled-icon-color;
        opacity: $chip-input-secondary-disabled-icon-opacity;
      }
    }

    .f-i {
      color: $chip-input-secondary-icon-color;
      &:hover {
        color: $chip-input-secondary-icon-hover-color;
      }
    }
  }

  &-input-danger {
    @include chip-base($bg: $chip-input-danger-bg, $c: $chip-input-danger-color);

    &:hover,
    &:hover:focus-visible {
      @include chip-base($bg: $chip-input-danger-hover-bg, $c: $chip-input-danger-color);
    }

    &:active:focus,
    &:hover:active {
      @include chip-base(
        $bg: $chip-input-danger-active-bg,
        $c: $chip-input-danger-color,
        $bs: $chip-input-danger-active-bs
      );
    }

    &:focus-visible,
    &.focus {
      @include chip-base($bg: $chip-input-danger-focus-bg, $bs: $chip-input-danger-focus-bs);
    }

    .f-i {
      color: $chip-input-danger-color;
      &:hover {
        color: $chip-input-danger-color;
      }
    }

    &[disabled],
    &.disabled {
      @include chip-base($bg: $chip-input-danger-disabled-bg, $c: $chip-input-disabled-color);

      &:hover,
      &:active,
      &:hover:active {
        @include chip-base($bg: $chip-input-danger-disabled-bg, $c: $chip-input-disabled-color);
      }

      .f-i {
        opacity: $chip-input-disabled-icon-opacity;
      }
    }
  }
}

.active {
  .chip-input {
    @include chip-base($bg: $chip-input-active-bg, $bc: $chip-input-active-bc);
  }

  .chip-input-secondary {
    @include chip-base($bg: $chip-input-secondary-active-bg);
  }
}

.multiple-suggester .chip-input-secondary.disabled {
  @extend %chip-input-secondary;

  &:focus-visible {
    @include chip-base($bg: $chip-input-secondary-bg, $bs: $chip-focus-bs);
  }
}

%chip-input-secondary {
  @include chip-base($bg: $chip-input-secondary-bg, $c: $chip-input-secondary-color);

  &:hover {
    @include chip-base($bg: $chip-input-secondary-hover-bg, $c: $chip-input-secondary-color);
  }

  &:hover:focus-visible {
    @include chip-base($bg: $chip-input-secondary-hover-bg, $c: $chip-input-secondary-color);
  }

  &:active {
    @include chip-base($c: $chip-input-secondary-color, $bg: $chip-input-secondary-pressed-bg);
  }

  &:hover:active {
    @include chip-base($c: $chip-input-secondary-color, $bg: $chip-input-secondary-pressed-bg);
  }

  &:focus {
    @include chip-base($bg: $chip-input-secondary-active-bg);
  }

  &:active:focus {
    @include chip-base($c: $chip-input-secondary-color, $bg: $chip-input-secondary-pressed-bg);
  }
}
