$kendo-upload-dropzone-border: $form-input-outline-bc;
$kendo-upload-status-text: $body-color;
$kendo-upload-text: $body-color;
$kendo-upload-error-text: $invalid-feedback;
$kendo-upload-progress-bg: $progress-bar-bg;
$kendo-upload-dropzone-bg: transparent;
$kendo-upload-border: $border-color;
$kendo-upload-icon-color: $color-neutral-400;
$kendo-subtle-text: $text-muted-color;
$kendo-progressbar-bg: $progress-bg;
