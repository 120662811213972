@mixin chip-base($fs: null, $lh: null, $p-x: null, $p-y: null, $c: null, $bg: null, $bs: null, $bc: null) {
  font-size: $fs;
  line-height: $lh;
  padding: $p-y $p-x;
  color: $c;
  background-color: $bg;
  border-color: $bc;
  box-shadow: $bs;
}

@mixin chip-outline-disabled {
  background-color: $chip-outline-disabled-bg;
  color: $chip-outline-disabled-color;
  border-color: $chip-outline-disabled-bd-color;
}
