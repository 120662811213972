$grouped-bar-colors: (
  1: $grouped-bar-fill-1,
  2: $grouped-bar-fill-2,
  3: $grouped-bar-fill-3,
  4: $grouped-bar-fill-4,
  5: $grouped-bar-fill-5,
  6: $grouped-bar-fill-6
);

@each $i, $color in $grouped-bar-colors {
  .f-grouped-bar-#{$i} {
    background-color: $color;
    fill: $color;
  }
}
