$box-shadow-primary-level-3: $shadow-primary-700-level-3-properties
  rgba($color-primary-700, $shadow-primary-700-level-3-opacity);
$box-shadow-focused-primary-level-1: $shadow-focused-level-1-properties
  rgba($color-primary-600, $shadow-focused-level-1-opacity);

$disabled-color-default-200: rgba($color-default-200, 0.5);
$disabled-color-neutral-500: rgba($color-neutral-500, 0.3);
$disabled-color-base: rgba($color-base, 0.7);

$btn-color-transparent: transparent;

$btn-primary-bg: $color-primary-600;
$btn-primary-color: $color-base;
$btn-primary-bg-hover: $color-primary-700;
$btn-primary-color-hover: $color-base;
$btn-primary-bs-focus: $shadow-focused-level-1-properties rgba($color-primary-400, $shadow-focused-level-1-opacity);
$btn-primary-bg-pressed: $color-primary-800;
$btn-primary-color-pressed: $color-base;
$btn-primary-color-focus: $color-base;
$btn-primary-bg-disabled: $color-primary-400;
$btn-primary-color-disabled: rgba($color-base, 0.6);

$btn-secondary-bg: $color-default-200;
$btn-secondary-color: $color-neutral-500;
$btn-secondary-bg-hover: $color-default-300;
$btn-secondary-color-hover: $color-neutral-500;
$btn-secondary-bg-pressed: $color-default-400;
$btn-secondary-bg-focus: $color-default-200;
$btn-secondary-bs-focus: $shadow-focused-level-1-properties rgba($color-primary-700, $shadow-focused-level-1-opacity);
$btn-secondary-color-pressed: $color-neutral-500;
$btn-secondary-color-focus: $color-neutral-500;
$btn-secondary-bg-disabled: $color-default-100;
$btn-secondary-color-disabled: rgba($color-neutral-500, 0.3);

$btn-warning-bg: $color-warning-700;
$btn-warning-color: $color-base;
$btn-warning-bg-hover: $color-warning-800;
$btn-warning-color-hover: $color-base;
$btn-warning-bg-pressed: $color-warning-900;
$btn-warning-color-pressed: $color-base;
$btn-warning-color-focus: $color-base;
$btn-warning-bs-focus: $shadow-focused-level-1-properties rgba($color-warning-500, $shadow-focused-level-1-opacity);
$btn-warning-bg-disabled: $color-warning-300;
$btn-warning-color-disabled: rgba($color-base, 0.7);

$btn-danger-bg: $color-danger-700;
$btn-danger-color: $color-base;
$btn-danger-bg-hover: $color-danger-800;
$btn-danger-color-hover: $color-base;
$btn-danger-bg-pressed: $color-danger-900;
$btn-danger-color-pressed: $color-base;
$btn-danger-color-focus: $color-base;
$btn-danger-bs-focus: $shadow-focused-level-1-properties rgba($color-danger-400, $shadow-focused-level-1-opacity);
$btn-danger-bg-disabled: $color-danger-200;
$btn-danger-color-disabled: rgba($color-base, 0.8);

$btn-outline-bg: transparent;
$btn-outline-bd-color: $color-neutral-200;
$btn-outline-bd-color-hover: $color-neutral-300;
$btn-outline-bd-color-pressed: $color-neutral-400;
$btn-outline-bd-color-disabled: $color-neutral-100;
$btn-outline-bg-disabled: transparent;
$btn-outline-color-disabled: rgba($color-neutral-400, 0.3);
$btn-outline-color-pressed-disabled: rgba($color-neutral-700, 0.3);

$btn-link-bg-hover: $color-primary-50;
$btn-link-box-shadow: $shadow-focused-level-1-properties rgba($color-primary-700, $shadow-focused-level-1-opacity);
$btn-link-bg-pressed: $color-primary-100;

$btn-icon-primary-bs-focus: $box-shadow-focused-primary-level-1, $box-shadow-primary-level-3;

$btn-icon-danger-bg: $color-default-200;
$btn-icon-danger-color: $color-neutral-500;
$btn-icon-danger-bg-hover: $color-danger-50;
$btn-icon-danger-color-hover: $color-danger-600;
$btn-icon-danger-color-active: $color-danger-600;
$btn-icon-danger-color-focus: $color-danger-600;
$btn-icon-danger-bg-focus: $color-danger-50;
$btn-icon-danger-bg-focus-visible: $color-danger-600;
$btn-icon-danger-box-shadow-focus-visible: $btn-danger-bs-focus;
$btn-icon-danger-bg-pressed: $color-danger-100;
$btn-icon-danger-bg-disabled: $disabled-color-default-200;
$btn-icon-danger-color-disabled: $disabled-color-neutral-500;

$btn-simple-icon-primary-color: $color-neutral-400;
$btn-simple-icon-primary-color-hover: $color-primary-700;
$btn-simple-icon-primary-bg-focus: $color-primary-100;
$btn-simple-icon-primary-color-focus: $color-primary-700;
$btn-simple-icon-primary-color-pressed: $color-primary-800;
$btn-simple-icon-primary-color-disabled: $color-neutral-400;

$btn-simple-icon-secondary-color: $color-neutral-400;
$btn-simple-icon-secondary-color-hover: $color-neutral-700;
$btn-simple-icon-secondary-color-disabled: $color-neutral-400;
$btn-simple-icon-secondary-color-active: $color-neutral-800;
$btn-simple-icon-secondary-color-focus: $color-neutral-700;
$btn-simple-icon-secondary-bg-focus: $color-default-200;
$btn-simple-icon-bs-focus: $shadow-focused-level-1-properties rgba($color-primary-400, $shadow-focused-level-1-opacity);

$btn-simple-icon-opacity: 0.3;
$btn-simple-icon-bg-active: $color-default-200;
$btn-simple-icon-color-active: $color-primary-700;

$btn-simple-icon-danger-bg: transparent;
$btn-simple-icon-danger-color: $color-neutral-400;
$btn-simple-icon-danger-color-hover: $color-danger-600;
$btn-simple-icon-danger-color-active: $color-danger-700;
$btn-simple-icon-danger-bg-focus: $color-danger-50;
$btn-simple-icon-danger-bg-focus-visible: $color-danger-100;
$btn-simple-icon-danger-box-shadow-focus-visible: null;
$btn-simple-icon-danger-bg-pressed: transparent;
$btn-simple-icon-danger-color-disabled: $color-neutral-400;
