@import './scheduler-variables';

.k-scheduler-header-wrap > .k-scheduler-table {
  border-bottom-color: $kendo-scheduler-border;
}

.k-scheduler-times-all-day,
.k-scheduler-table th {
  color: $text-muted-color;
}

.k-scheduler-header {
  background-color: $k-scheduler-header-bg;
}

.k-scheduler-header {
  border-color: $kendo-scheduler-border;
}

.k-scheduler {
  ::selection {
    background-color: $color-accent-3-200;
    color: $color-neutral-700;
  }
}

kendo-scheduler {
  .k-marquee-color {
    border-color: $k-marquee-border-color;
    background-color: $k-marquee-bg-color;
  }
}

.k-scheduler-header-cell {
  color: $body-color;
}

.k-scheduler-monthview {
  th {
    color: $body-color;
  }

  @media screen and (min-width: $grid-breakpoint-sm) {
    .k-event {
      border-right-color: $k-scheduler-event-border-color;
    }
  }

  @media screen and (min-width: $grid-breakpoint-xl) {
    .k-event {
      border-right-color: $k-scheduler-event-border-color;
    }
  }
}

.k-scheduler-month-day-pointer {
  border-radius: $border-radius-100;

  &:hover {
    background-color: $k-scheduler-day-bg-hover;
  }
}

.k-scheduler-today {
  border-color: $k-scheduler-today-border-color;
}

.k-scheduler-month-today {
  border-color: $k-scheduler-today-border-color;
}

.k-scheduler-month-day-slot {
  .f-i-plus {
    color: $k-scheduler-month-day-slot-icon-color;
  }
}

.k-scheduler-other-month {
  color: $k-scheduler-other-month-color;
}

.k-scheduler-agendaview {
  border-color: $k-scheduler-event-border-color;
}

.k-scheduler-agendaview .k-scheduler-content .k-scheduler-table {
  tr:hover {
    background-color: initial;
    color: initial;
    border-color: inherit;

    .k-scheduler-datecolumn {
      background-color: $kendo-table-header-bg;
    }
  }

  .k-scheduler-cell.k-selected {
    background-color: transparent;
  }

  .k-scheduler-datecolumn {
    background-color: $kendo-table-header-bg;
  }
}

.k-scheduler .ca-agenda-view {
  background-color: $kendo-scheduler-bg;
}
