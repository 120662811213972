@import 'firefly/styles/mixin';
@import './color-maps';

@each $name, $color in $base-color-map {
  .bg-#{$name} {
    @include enable-important('background-color', $color);
    @include enable-important('fill', $color);
  }

  .border-#{$name} {
    @include enable-important('border-color', $color);
    @include enable-important('stroke', $color);
  }

  .text-#{$name} {
    @include enable-important('color', $color);
    @include enable-important('fill', $color);
  }

  .text-#{$name}-hover:hover {
    @include enable-important('color', $color);
    @include enable-important('fill', $color);
  }

  .bg-#{$name}-hover:hover {
    @include enable-important('background-color', $color);
  }

  .border-#{$name}-hover:hover {
    @include enable-important('border-color', $color);
  }
}

.bg-transparent {
  @include enable-important('fill', transparent);
}

@each $palette, $tones in $color-map {
  @each $tone_name, $tone_value in $tones {
    .text-#{$palette}-#{$tone_name} {
      @include enable-important('color', $tone_value);
      @include enable-important('fill', $tone_value);
    }

    .text-#{$palette}-#{$tone_name}-hover:hover {
      @include enable-important('color', $tone_value);
      @include enable-important('fill', $tone_value);
    }

    .bg-#{$palette}-#{$tone_name} {
      @include enable-important('background-color', $tone_value);
      @include enable-important('fill', $tone_value);
    }

    .bg-#{$palette}-#{$tone_name}-hover:hover {
      @include enable-important('background-color', $tone_value);
    }

    .border-#{$palette}-#{$tone_name} {
      @include enable-important('border-color', $tone_value);
      @include enable-important('stroke', $tone_value);
    }

    .border-#{$palette}-#{$tone_name}-hover:hover {
      @include enable-important('border-color', $tone_value);
    }
  }
}
