@import 'maps-variables';

.f-map {
  .leaflet-control-zoom {
    .leaflet-control-zoom-in,
    .leaflet-control-zoom-out {
      -webkit-tap-highlight-color: $btn-secondary-bg-pressed;

      &:hover {
        background-color: $btn-secondary-bg-hover;
      }

      &.leaflet-disabled {
        color: $btn-secondary-color-disabled;
        background-color: $btn-secondary-bg-disabled;
      }
    }
  }

  .leaflet-control-attribution {
    background: $map-attribution-bg-color;

    &,
    a {
      color: map-deep-get($link-colors, 'secondary', 'color');
    }
  }

  .leaflet-tooltip,
  .leaflet-popup-tip,
  .leaflet-popup-content-wrapper {
    color: $popover-body-color;
    background: $popover-bg;
  }

  .leaflet-tooltip {
    &-left:before {
      border-left-color: $popover-bg;
    }

    &-right:before {
      border-right-color: $popover-bg;
    }

    &-top:before {
      border-top-color: $popover-bg;
    }

    &-bottom:before {
      border-bottom-color: $popover-bg;
    }
  }

  &.dark {
    background-color: $color-neutral-700;

    .leaflet-tile-pane .leaflet-layer {
      filter: brightness(1.4) contrast(1.005);
    }
  }

  .f-marker-cluster-area {
    stroke: $marker-cluster-area-color;
    fill: $marker-cluster-area-color;
  }

  .leaflet-marker-icon {
    .cluster-marker {
      background: $location-bubble-color;

      &-center {
        background: $location-bubble-color;
      }
    }
  }

  .f-location-bubble,
  .f-bubble-center {
    fill: $location-bubble-color;
  }

  .f-bubble-tooltip {
    background: $location-bubble-color;
    color: $color-base;
  }
}
