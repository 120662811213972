%form-control-hover {
  background-color: $form-input-bg-hover;
  border-color: $form-input-bc-hover;
  box-shadow: $form-input-bs-hover;
}

%form-control-outline-hover {
  border-color: $form-input-outline-bc-hover;
  background: $form-input-outline-bg-hover;
}

%form-control-focus {
  background-color: $form-input-bg-focus;
  border-color: $form-input-bc-focus;
  box-shadow: $form-input-bs-focus;
}

%form-control-disabled {
  background-color: $form-input-bg-disabled;
  border-color: $form-input-bc-disabled;
  color: $form-input-color-disabled;
  box-shadow: none;
}

%form-control-outline-disabled {
  border-color: $form-input-outline-bc-disabled;
  background: $form-input-outline-bg-disabled;
  color: $form-input-outline-color-disabled;
}
