@import 'firefly/styles/mixin';

$border-dashed-map: (
  dashed: 'border',
  top-dashed: 'border-top',
  end-dashed: 'border-right',
  bottom-dashed: 'border-bottom',
  start-dashed: 'border-left'
);

$border-dashed-value: $border-width-1 $border-style-dashed $border-color;

@each $name, $property in $border-dashed-map {
  .border-#{$name} {
    @include enable-important($property, $border-dashed-value);
  }
}
