@import './common-variables';

.stripes-pattern-bg-color-1 {
  fill: $stripes-pattern-color-1;
}

.stripes-pattern-bg-color-2 {
  fill: $stripes-pattern-color-2;
}

.bg-stripes-pattern {
  background: repeating-linear-gradient(
    -45deg,
    $stripes-pattern-color-1,
    $stripes-pattern-color-1 2px,
    $stripes-pattern-color-2 2px,
    $stripes-pattern-color-2 4px
  );
}

.bg-stripes-mask {
  background: repeating-linear-gradient(
    -45deg,
    $stripes-mask-color,
    $stripes-mask-color 2px,
    transparent 2px,
    transparent 4px
  );
}
