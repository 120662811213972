.f-svg-donut-chart {
  g.f-arc:nth-child(3n + 1) {
    fill: $svg-donut-chart-arc-fill-1;
  }

  g.f-arc:nth-child(3n + 2) {
    fill: $svg-donut-chart-arc-fill-2;
  }

  g.f-arc:nth-child(3n + 3) {
    fill: $svg-donut-chart-arc-fill-3;
  }
}
