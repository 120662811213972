@import './suggesters-variables';

.single-suggester {
  .input-group-right-icon {
    &.is-valid,
    &.is-warning,
    &.is-invalid {
      .f-i-close {
        color: $form-icon-color;

        &:hover {
          color: $body-color;
        }
      }
    }
  }
}

.multiple-suggester {
  &.action-wrapper.list-group-item {
    border-bottom-color: $border-color;
  }

  .form-control {
    &.input-focus {
      @extend %form-control-focus;
    }
    input {
      color: $multiple-suggester-input-color;
      &::placeholder {
        color: $multiple-suggester-placeholder-color;
      }

      &[disabled],
      &.disabled {
        color: $form-input-color-disabled;

        &::placeholder {
          color: $form-input-placeholder-color-disabled;
        }
      }
    }
  }

  .form-control-outline {
    input {
      &.disabled {
        color: $form-input-outline-color-disabled;

        &::placeholder {
          color: $form-input-outline-placeholder-color-disabled;
        }
      }
    }

    &.input-focus {
      background-color: $form-valid-outline-bg-focus;
      border-color: $form-input-outline-bc-focus;

      &.is-valid,
      &.is-warning,
      &.is-invalid {
        background-color: $form-valid-outline-bg;
      }
    }
  }

  .dropdown-item:focus-visible {
    background-color: $multiple-suggester-dropdown-item-bg-focus;
  }
}

.single-suggester,
.multiple-suggester {
  .f-i-close:focus-visible {
    border-radius: $border-radius-1;
    outline: none;
  }
}

.powered-by-google-logo {
  background-image: $powered-by-google-logo-path;
}

.suggester-add-item-link {
  @extend .link, .link-primary;
}

.f-new-suggester-item {
  @keyframes f-new-suggester-item-animation {
    10% {
      background-color: $new-suggester-item-bg;
    }
    80% {
      background-color: $new-suggester-item-bg;
    }
  }

  &.animated {
    animation-name: f-new-suggester-item-animation;
    animation-timing-function: ease-out;
    animation-duration: 1500ms;
  }
}
