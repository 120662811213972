.scale-chart-bar {
  &:nth-child(1) {
    background-color: $color-success-600;
  }
  &:nth-child(2) {
    background-color: $color-success-400;
  }
  &:nth-child(3) {
    background-color: $color-warning-400;
  }
  &:nth-child(4) {
    background-color: $color-accent-4-500;
  }
  &:nth-child(5) {
    background-color: $scale-chart-bar-bg-5;
  }
}
