.k-colorpicker {
  .k-picker-wrap {
    border-radius: $border-radius-2;
  }

  .k-select,
  .k-tool-icon {
    background-color: $kendo-button-bg;

    &:hover,
    &:active {
      background-color: $kendo-button-hover-bg;
    }
  }
}
