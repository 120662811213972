$grid-locked-shadow-props: 2px 0px 7px 0px;
$grid-locked-shadow-color: rgba($color-primary-700, 0.2);
$k-grid-border-color: $color-neutral-100;
$k-drag-clue-color: $color-neutral-700;
$k-drag-clue-border-color: $color-default-300;
$k-drag-clue-bg: $color-default-50;
$k-footer-template-hover: $kendo-grid-hover-bg;
$k-grid-footer-wrap-td-padding-y: 0.5rem;
$k-grid-footer-bg: $color-default-50;
$k-footer-highlighted-cell-bg: $color-default-200;
$k-highlighted-row-cell-bg: $color-warning-50;
$k-highlighted-collapsed-row-cell-bg: $color-default-50;
$k-cell-shadow-properties: inset 0 0 0 1px;
$k-cell-shadow-color-hover: $color-default-500;
$k-cell-bg-hover: $color-default-100;
$k-cell-shadow-color-active: $color-default-600;
$k-cell-shadow-color-selected: $color-primary-600;
$k-cell-shadow-color-selected-hover: $color-primary-800;
$k-cell-shadow-color-selected-active: $color-primary-600;
$k-cell-shadow-color-selected-focus-active: $color-primary-800;
$k-cell-focused-state-color: $color-primary-400;
$k-cell-focused-state-shadow: inset $shadow-focused-level-1-properties
  rgba($k-cell-focused-state-color, $shadow-focused-level-1-opacity);
$k-context-menu-item-bg: $color-default-100;
$k-context-menu-open-cell-bg: $color-primary-100;
$k-context-menu-open-sticky-cell-bg: $color-primary-100;
$k-context-menu-open-marker-bg: $color-primary-600;
$grid-body-row-bg: $color-base;
$k-header-hover-bg: $color-default-200;
$k-column-resizer-color: $color-default-200;
$cell-with-note-hover-bg: $color-default-300;
$cell-activity-bg: $color-warning-100;
$cell-activity-hover-bg: $color-warning-300;
$cell-with-note-bg: $color-default-100;
$cell-with-note-marker-bg: $color-default-500;
$cell-activity-auto-mark-bg: $color-warning-50;
$cell-activity-auto-mark-bg-marker: $color-warning-500;
$cell-activity-with-note-marker: $color-default-500;

$k-interactive-cell-bg: $color-default-200;
$k-interactive-cell-selected-bg: $color-default-300;
$k-interactive-cell-selected-marker-color: $color-primary-500;

$k-grouping-table-row: $color-default-50;
$k-grouping-table-row-hover: $color-default-200;
