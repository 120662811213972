@import './variables';

.quarter-range {
  background-color: $quarter-range-bg;
}

.quarter-range-start,
.quarter-range-end {
  .quarter-cell {
    background-color: $quarter-cell-edge-bg;
    color: $quarter-cell-edge-color;

    &:hover {
      background-color: $quarter-cell-edge-bg-hover;
    }
  }
}

.quarter-cell {
  &:hover {
    background-color: $quarter-cell-bg-hover;
  }
}

.quarter-cell-year {
  color: $quarter-cell-year-color;
}

.carousel-control-prev-icon {
  background-image: url('#{$brand-icon-assets-path}' + 'chevron-left.svg');
}

.carousel-control-next-icon {
  background-image: url('#{$brand-icon-assets-path}' + 'chevron-right.svg');
}
