@import 'firefly/styles/mixin';
@import './logo-variables';

@mixin logo-divider {
  margin-right: $logo-img-margin-right;

  &::after {
    content: '';
    width: $border-width-1;
    height: $logo-img-border-height;
    background-color: $border-color;
    @include position(absolute, $logo-img-border-top, -$logo-img-margin-right, null, null);
    transform: translateY(-$logo-img-border-top);
  }
}

.f-brand-logo {
  height: $logo-img-height;
  width: $logo-img-width;
  background-image: url('#{$brand-assets-path}' + 'logo.svg');
  top: $logo-img-position-top;
}

@media (min-width: $grid-breakpoint-lg) {
  .f-brand-logo:not(.f-brand-logo-static) {
    @include logo-divider;
  }
}

.f-brand-logo-static {
  @include logo-divider;
}
