@mixin empty-state($options) {
  @each $option in $options {
    &.#{$option} {
      @if ($option == 'mobile-view' or $option == 'tablet-view') {
        background: url('#{$brand-assets-common-path}mobile-view.svg') no-repeat;
      } @else {
        background: url('#{$brand-assets-common-path}#{$option}.svg') no-repeat;
      }
    }
  }
}
