@import './validation-maps';
@import './validation-variables';

.form-control,
.form-select {
  &.is-valid {
    background-color: $form-valid-bg;
    border-color: $form-valid-bc;
    box-shadow: $form-valid-bs;
    &:hover {
      @extend %is-valid-hover;
    }
    &:focus,
    &:focus:hover {
      @extend %is-valid-focus;
    }
    ~ .f-i:first-of-type {
      color: $form-valid-color;
    }
  }

  &.is-warning {
    background-color: $form-warning-bg;
    border-color: $form-warning-bc;
    box-shadow: $form-warning-bs;
    &:hover {
      @extend %is-warning-hover;
    }
    &:focus,
    &:focus:hover {
      @extend %is-warning-focus;
    }
    ~ .f-i:first-of-type {
      color: $form-warning-color;
    }
  }

  &.is-invalid {
    background-color: $form-invalid-bg;
    border-color: $form-invalid-bc;
    box-shadow: $form-invalid-bs;
    &:hover {
      @extend %is-invalid-hover;
    }
    &:focus,
    &:focus:hover {
      @extend %is-invalid-focus;
    }

    ~ .f-i:first-of-type {
      color: $form-invalid-color;
    }
  }
}

.form-control.form-control-outline,
.form-select.form-select-outline {
  &.is-valid {
    background-color: $form-valid-outline-bg;
  }
  &.is-warning {
    background-color: $form-warning-outline-bg;
  }
  &.is-invalid {
    background-color: $form-invalid-outline-bg;
  }

  &.is-valid,
  &.is-warning,
  &.is-invalid {
    &:hover {
      background-color: $form-valid-outline-bg-hover;
    }
    &:focus,
    &:focus:hover {
      background-color: $form-valid-outline-bg-focus;
    }
  }
}

@each $type, $color in $validation-feedback-map {
  .#{$type}-feedback {
    color: $color;
  }
}
