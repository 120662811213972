$kendo-calendar-border: transparent;

$calendar-header-min-width: 280px;
$kendo-calendar-header-padding-x: 1.5rem;
$kendo-calendar-header-padding-y: 0.5rem;
$calendar-header-cell-height: 20px;
$calendar-header-cell-mobile-height: 24px;
$kendo-calendar-header-cell-font-size: 12px;
$kendo-calendar-header-cell-line-height: 20px;
$calendar-header-cell-padding-y: 0;
$calendar-header-cell-opacity: 1;
$kendo-calendar-nav-gap: 0.25rem;

$kendo-calendar-view-gap: 20px;

$kendo-calendar-cell-size: 32px;
$kendo-calendar-header-cell-height: 20px;
$calendar-cell-mobile-size: 36px;
$kendo-calendar-cell-border-radius: 50%;
$calendar-cell-hover-border: transparent;
$calendar-cell-selected-border: transparent;
$kendo-calendar-cell-selected-focus-shadow: none;

$calendar-other-month-opacity: 1;

$kendo-calendar-range-cell-border-radius: 50%;
