.k-input .k-button-solid-base {
  background-color: $color-base;
  border-left-width: 0;
}

.k-input,
.k-picker {
  border-radius: $border-radius-1;
}

.k-picker {
  border-color: $color-default-400;
}

.k-input-outline {
  background-color: $form-input-outline-bg !important; // to override !important in kendo styles

  &:hover {
    background-color: $form-input-outline-bg-hover !important; // to override !important in kendo styles
  }

  &.k-focus,
  &.k-focus:hover,
  &:focus,
  &:focus:hover {
    background-color: $form-input-outline-bg-focus !important; // to override !important in kendo styles
  }
}

.k-picker-solid {
  &:focus:hover {
    background-color: $btn-secondary-bg-hover;
  }
}

.k-input-solid {
  &:focus-within,
  &:focus,
  &.k-focus {
    color: $kendo-input-solid-focus-color;
  }
}
