@import './accordion-variables';

.accordion {
  &-item {
    border-bottom-color: $accordion-item-border-color;
  }

  &-button {
    color: $accordion-button-color;
    border-radius: $border-radius-1;

    &::before {
      color: $text-muted-color;
      font-family: $accordion-icon-font-family;
      @extend .f-i-caret-down;
    }

    &.collapsed::before {
      @extend .f-i-caret-right;
    }

    &:focus-visible {
      outline: none;
      box-shadow: $accordion-item-focus-box-shadow;
    }
  }
}
