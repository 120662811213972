@import 'bootstrap/scss/functions';
@import 'variables';

.form-select {
  &:hover {
    background-color: $form-input-bg-hover;
    border-color: $form-input-bc-hover;
  }

  &:focus {
    background-color: $form-input-bg-focus;
    border-color: $form-input-bc-focus;
  }

  &:disabled,
  &.disabled {
    background-color: $form-input-bg-disabled;
    background-image: escape-svg($select-bg-image-disabled);
    border-color: $form-input-bc-disabled;
    color: $form-input-color-disabled;

    ~ .form-icon {
      color: $form-icon-color-disabled;
    }
  }

  option {
    background-color: $dropdown-bg;
  }
}

.form-select-outline {
  border-color: $form-input-outline-bc;
  background-color: $form-input-outline-bg;

  &:hover {
    border-color: $form-input-outline-bc-hover;
    background-color: $form-input-outline-bg-hover;
  }

  &:focus {
    border-color: $form-input-outline-bc-focus;
    background-color: $form-input-outline-bg-focus;
  }

  &:disabled,
  &.disabled {
    border-color: $form-input-outline-bc-disabled;
    background-color: $form-input-outline-bg-disabled;
    color: $form-input-outline-color-disabled;
  }
}

.form-select-with-icon {
  & .form-icon {
    color: $form-icon-color;
  }
}
