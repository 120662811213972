@import './button-mixins';
@import './button-variables';

.btn {
  &.btn-primary {
    @include btn-style(
      $btn-primary-bg,
      $btn-primary-color,
      null,
      $btn-primary-bg-hover,
      $btn-primary-color-hover,
      null,
      $btn-primary-bg,
      $btn-primary-bs-focus,
      null,
      $btn-primary-bg-pressed,
      $btn-primary-color-pressed,
      null,
      $btn-primary-color-focus,
      $btn-primary-bg-disabled,
      $btn-primary-color-disabled,
      null
    );
  }

  &.btn-secondary {
    @include btn-style(
      $btn-secondary-bg,
      $btn-secondary-color,
      null,
      $btn-secondary-bg-hover,
      $btn-secondary-color-hover,
      null,
      $btn-secondary-bg,
      $btn-secondary-bs-focus,
      null,
      $btn-secondary-bg-pressed,
      $btn-secondary-color-pressed,
      null,
      $btn-secondary-color-focus,
      $btn-secondary-bg-disabled,
      $btn-secondary-color-disabled,
      null
    );
  }

  &.btn-warning {
    @include btn-style(
      $btn-warning-bg,
      $btn-warning-color,
      null,
      $btn-warning-bg-hover,
      $btn-warning-color-hover,
      null,
      $btn-warning-bg,
      $btn-warning-bs-focus,
      null,
      $btn-warning-bg-pressed,
      $btn-warning-color-pressed,
      null,
      $btn-warning-color-focus,
      $btn-warning-bg-disabled,
      $btn-warning-color-disabled,
      null
    );
  }

  &.btn-danger {
    @include btn-style(
      $btn-danger-bg,
      $btn-danger-color,
      null,
      $btn-danger-bg-hover,
      $btn-danger-color-hover,
      null,
      $btn-danger-bg,
      $btn-danger-bs-focus,
      null,
      $btn-danger-bg-pressed,
      $btn-danger-color-pressed,
      null,
      $btn-danger-color-focus,
      $btn-danger-bg-disabled,
      $btn-danger-color-disabled,
      null
    );
  }

  &.btn-outline {
    border: 1px solid;
    @include btn-style(
      $btn-outline-bg,
      $btn-outline-color,
      $btn-outline-bd-color,
      null,
      $btn-outline-color-hover,
      $btn-outline-bd-color-hover,
      null,
      $btn-secondary-bs-focus,
      null,
      null,
      $btn-outline-color-pressed,
      $btn-outline-bd-color-pressed,
      null,
      $btn-outline-bg-disabled,
      $btn-outline-color-disabled,
      $btn-outline-bd-color-disabled
    );
  }

  &.btn-link {
    &:hover,
    &:hover:focus-visible {
      background-color: $btn-link-bg-hover;
    }

    &:focus-visible {
      background-color: transparent;
      box-shadow: $btn-link-box-shadow;
    }

    &:active:focus {
      background-color: $btn-link-bg-pressed;
    }

    &:disabled {
      background-color: transparent;
      color: $btn-link-disabled-color;
    }
  }

  &.btn-icon {
    &.btn-primary:focus-visible {
      box-shadow: $btn-icon-primary-bs-focus;
    }

    &.btn-danger {
      @include btn-style(
        $btn-icon-danger-bg,
        $btn-icon-danger-color,
        null,
        $btn-icon-danger-bg-hover,
        $btn-icon-danger-color-hover,
        null,
        $btn-icon-danger-bg-focus-visible,
        $btn-icon-danger-box-shadow-focus-visible,
        null,
        $btn-icon-danger-bg-pressed,
        null,
        null,
        null,
        $btn-icon-danger-bg-disabled,
        $btn-icon-danger-color-disabled,
        null
      );

      &:active:focus {
        color: $btn-icon-danger-color-active;
      }

      &:focus-visible {
        background-color: $btn-icon-danger-bg-focus;
        color: $btn-icon-danger-color-focus;
      }
    }
  }

  &.btn-simple-icon {
    &.btn-primary {
      @include btn-style(
        transparent,
        $btn-simple-icon-primary-color,
        null,
        null,
        $btn-simple-icon-primary-color-hover,
        null,
        $btn-simple-icon-primary-bg-focus,
        null,
        $btn-simple-icon-primary-color-focus,
        transparent,
        $btn-simple-icon-primary-color-pressed,
        null,
        null,
        null,
        $btn-simple-icon-primary-color-disabled,
        null
      );
    }

    &.btn-secondary {
      @include btn-style(
        transparent,
        $btn-simple-icon-secondary-color,
        null,
        null,
        $btn-simple-icon-secondary-color-hover,
        null,
        $btn-simple-icon-secondary-bg-focus,
        $btn-simple-icon-bs-focus,
        $btn-simple-icon-secondary-color-focus,
        transparent,
        null,
        $btn-simple-icon-secondary-color-active,
        null,
        null,
        $btn-simple-icon-secondary-color-disabled,
        null
      );
    }

    &.btn-danger {
      @include btn-style(
        $btn-simple-icon-danger-bg,
        $btn-simple-icon-danger-color,
        null,
        null,
        $btn-simple-icon-danger-color-hover,
        null,
        $btn-simple-icon-danger-bg-focus-visible,
        null,
        null,
        $btn-simple-icon-danger-bg-pressed,
        $btn-simple-icon-danger-color-active,
        null,
        null,
        null,
        $btn-simple-icon-danger-color-disabled,
        null
      );
    }

    &:disabled {
      opacity: $btn-simple-icon-opacity;
    }
  }

  &-rounded {
    border-radius: $border-radius-100;
  }
}

.btn-group {
  > .btn:not(:last-child):not(.dropdown-toggle),
  > .btn-group > .btn-group:not(:last-child) > .btn {
    border-top-right-radius: $btn-group-inner-border-radius;
    border-bottom-right-radius: $btn-group-inner-border-radius;
  }

  // Left border radius on inner button group items
  > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: $btn-group-inner-border-radius;
    border-bottom-left-radius: $btn-group-inner-border-radius;
  }
}

.show > .btn-primary.dropdown-toggle {
  background: $btn-primary-bg-pressed;

  &:focus-visible {
    box-shadow: $btn-primary-bs-focus;
  }
}

.show > .btn-secondary.dropdown-toggle {
  background-color: $btn-secondary-bg-pressed;
  color: $btn-secondary-color;

  &:focus-visible {
    box-shadow: $btn-secondary-bs-focus;
  }
}

.show > .btn-outline.dropdown-toggle {
  border-color: $btn-outline-bd-color-pressed;
  color: $btn-outline-color-pressed;
}

.show > .btn-simple-icon.dropdown-toggle {
  background-color: $btn-simple-icon-bg-active;
  color: $btn-simple-icon-color-active;

  &:focus {
    background-color: $btn-simple-icon-bg-active;
  }
}
