@mixin date-time-validation-state($validation, $collapsed: false) {
  $element: #{if($collapsed, '.k-dateinput', '')};

  &.is-#{$validation} {
    &:hover #{$element} {
      @extend %is-#{$validation}-hover;
      box-shadow: if($validation == 'invalid', $form-invalid-bs, $form-warning-bs);
    }

    &.k-focus #{$element},
    &.k-focus:hover #{$element} {
      @extend %is-#{$validation}-hover;
    }

    &#{if($collapsed, ".f-is-outlined", ".k-input-outline")} {
      $outline-bg: if($validation == 'invalid', $form-invalid-outline-bg, $form-warning-outline-bg);

      @if $collapsed {
        .k-dateinput {
          background-color: $outline-bg !important; // to override !important in kendo styles
        }
      } @else {
        background-color: $outline-bg !important; // to override !important in kendo styles
      }

      &:hover #{$element} {
        background-color: if(
          $validation == 'invalid',
          $form-invalid-outline-bg-hover,
          $form-warning-outline-bg-hover
        ) !important; // to override !important in kendo styles
      }

      &.k-focus #{$element},
      &.k-focus:hover #{$element} {
        background-color: if(
          $validation == 'invalid',
          $form-invalid-outline-bg-focus,
          $form-warning-outline-bg-focus
        ) !important; // to override !important in kendo styles
      }
    }
  }
}
