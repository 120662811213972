@mixin skeleton-bg-gradient($opacity) {
  background-image: linear-gradient(
    125deg,
    transparent 0%,
    transparent 30%,
    rgba($skeleton-gradient-color, $opacity) 50%,
    transparent 70%,
    transparent 100%
  );
}
