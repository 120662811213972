.step-link-active {
  &:hover,
  &:active:focus {
    color: $link-main-color;
  }
}

.step-link {
  @extend .link-secondary;
}

.step-text-disabled {
  color: $link-main-color-disabled;
}
