@import '../../../kendo-styles/config/calendar-config';
@import './calendar-variables';

.k-popup .k-calendar {
  border-color: $k-calendar-border-color;
  box-shadow: $k-calendar-box-shadow;
}

.k-calendar {
  border-radius: $border-radius-2;
  color: $k-calendar-text-color;

  .k-icon {
    color: $k-calendar-icon-color;

    &:hover {
      color: $k-calendar-icon-hover-color;
    }
  }

  .k-nav-today {
    &:active {
      color: $k-calendar-today-link-color-active;
    }

    &:focus-visible {
      box-shadow: inset $shadow-focused-level-1-properties
        rgba($k-calendar-today-link-shadow-color, $shadow-focused-level-1-opacity);
      color: $k-calendar-today-link-color-focus;
      border-radius: $border-radius-1;
    }

    &:disabled,
    &[disabled],
    &.disabled {
      color: $k-calendar-today-link-color-disabled;
    }
  }

  .k-other-month .k-link {
    color: $k-calendar-other-month-link-color;
  }

  &-nav {
    @media (hover: hover) {
      .k-button-flat:focus {
        box-shadow: $k-calendar-button-bs !important;
      }
    }
  }
}

.k-calendar .k-calendar-td.k-selected:hover .k-link {
  background-color: $k-calendar-selected-hover-bg;
}

.k-calendar-header {
  color: $k-calendar-header-color;

  .k-calendar-title {
    color: inherit;
  }

  .k-title {
    color: $color-primary-700;

    &:hover {
      background-color: $color-success-50 !important; // to override !important in kendo styles
    }

    &.k-state-disabled:hover {
      background-color: transparent !important;
    }
  }

  .k-button {
    &::after {
      box-shadow: none;
    }

    &:disabled {
      opacity: $k-button-opacity-disabled;
    }
  }
}

.k-state-disabled .k-calendar-tbody,
.k-calendar .k-calendar-table .k-state-disabled {
  opacity: $k-calendar-disabled-opacity;
}

// styles for mini-calendar
div:not(.k-popup):not(.dropdown-menu) > {
  .k-calendar,
  .f-mobile-calendar,
  .f-mobile-calendar .k-calendar-table {
    background-color: $k-mini-calendar-bg;
  }

  .k-calendar .k-calendar-view .k-today.k-state-focused .k-link {
    box-shadow: $k-calendar-focused-link-box-shadow;
  }

  .k-calendar .k-calendar-view .k-today.k-state-focused:hover .k-link {
    box-shadow: $k-calendar-today-link-box-shadow-hover;
  }
}

.k-calendar {
  .k-range-mid {
    background-color: $k-calendar-range-mid-bg;
  }

  .k-range-start {
    background-image: linear-gradient(
      90deg,
      transparent 0%,
      transparent 45%,
      $k-calendar-range-mid-bg 45%,
      $k-calendar-range-mid-bg 100%
    );
  }

  .k-range-end {
    background-image: linear-gradient(
      90deg,
      $k-calendar-range-mid-bg 0%,
      $k-calendar-range-mid-bg 45%,
      transparent 45%,
      transparent 100%
    );
  }
}

.k-calendar-nav-today {
  color: $k-calendar-nav-today-color;

  &:hover {
    color: $k-calendar-nav-today-color-hover;
  }
}

.f-mobile-calendar {
  .k-calendar-title {
    color: $mobile-calendar-nav-fast-color;
  }

  .k-calendar-td:hover .k-link {
    border-color: transparent;
    background-color: transparent;
  }

  .k-calendar-tr .k-calendar-td.k-selected .k-link {
    background-color: $calendar-cell-selected-bg;
  }

  & ~ .f-expand-mobile-calendar-btn {
    border-radius: $border-radius-4;

    &,
    &:hover,
    &:active {
      background-color: $k-mini-calendar-bg;
      border-bottom-color: $mobile-calendar-expand-btn-border-color;
    }

    .f-i {
      color: $mobile-calendar-expand-btn-icon-color;
    }
  }
}
