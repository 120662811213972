@use 'sass:math';
@import 'firefly/styles/functions';
@import './popover-variables';

@mixin popover-base() {
  > .popover-arrow {
    position: absolute;

    &::before,
    &::after {
      background-color: $popover-bg;
    }

    &::before {
      box-shadow: $popover-box-shadow;
      border-color: $popover-border-color;
    }
  }

  @each $state, $color in $popover-states {
    $bg: map-deep-get($popover-states, $state, 'bg');
    $bd: map-deep-get($popover-states, $state, 'bd');

    &-#{$state} {
      @include color-variant($bg, $bd);
      @include box-shadow($popover-bs-properties $popover-bs-color);

      > .popover-arrow::before {
        background-color: $bg;
        border-color: $bd;
      }

      > .popover-arrow::after {
        background-color: $bg;
      }
    }
  }
}
