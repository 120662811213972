@import './inner-error-variables';

.inner-error {
  &-img {
    background-image: url('#{$brand-assets-common-path}' + '#{$inner-error-img}.svg');
    background-repeat: no-repeat;
    background-size: contain;
  }

  &-link {
    @extend .link, .link-primary;
  }
}
