@include mobile-phones-only {
  .card-row-search-container {
    .multiple-suggester .dropdown-toggle, .single-suggester input {
      background-image: url('#{$brand-icon-layouts-assets-path}' + 'search.svg');
    }
  }
}

.increased-baseline-size {
  .card-row-filters-container {
    f-editable-chip-group.has-chips {
      & + .btn.btn-secondary.btn-icon {
        @extend .notification-marker;
      }
    }
  }
}

.mobile-search-inactive:not(.search-animation-running) .search-notification,
.card-row-search-container .multiple-suggester-with-items {
  @extend .notification-marker;
}
