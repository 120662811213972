@import './lists-variables';
@import './lists-variables';

.list-group {
  .drag-target {
    &.dragging {
      background: $list-dragging-item-active-bg;
      box-shadow: $list-dragging-item-active-bs;

      .f-i.f-i-drag-drop {
        color: $list-dragging-active-icon-color;
      }
    }
    .f-i.f-i-drag-drop.f-draggable-list-item-drag-icon-disabled {
      color: $draggable-list-item-drag-icon-disabled-color;
    }
  }

  &.draggable.active,
  .active {
    .drop-target {
      &.dragging-start-place {
        border-color: $list-dragging-item-start-border-color;
      }

      &.new-drop-place {
        border-color: $list-dragging-item-new-border-color;
        background: $list-dragging-item-new-bg;
      }
    }
  }

  &.draggable {
    .list-group-item {
      &.active.disabled {
        color: inherit;
      }
    }
  }

  .form-check {
    .form-check-input {
      &.active.disabled {
        color: $list-group-item-active-disabled-color;
      }
    }
  }

  &-item {
    @media (hover: hover) {
      &-hover {
        &:hover:not(.disabled) {
          background-color: $list-group-item-hover-bg;
        }
      }
    }

    &.active.disabled {
      background-color: $list-group-item-active-disabled-bg;
      color: $list-group-item-active-disabled-color;
    }

    &:focus-visible {
      box-shadow: $list-focus-shadow;
    }

    &.active {
      background-color: $dropdown-item-bg-focus;
    }

    &-concised {
      &:focus-visible {
        border-radius: $border-radius-1;
      }

      &.disabled .f-i {
        opacity: $list-group-icon-disabled-opacity;
      }

      .f-i {
        &:focus-visible {
          box-shadow: $list-focus-shadow;
          border-radius: $border-radius-1;
        }
      }
    }

    &-danger,
    &-danger:hover {
      color: $list-item-danger-color;
      background-color: inherit;

      &.disabled {
        color: $color-danger-300;
      }
    }
  }

  &-secondary-text {
    color: $list-group-item-secondary-text-color;
  }
}

.list-item-removable-on-hover {
  &:hover:not(.disabled) {
    background-color: $list-group-item-hover-bg;
  }
}

.list-group-with-background {
  .list-group-item {
    background: $list-item-with-background-bg;
    border-radius: $border-radius-2;

    &:focus-visible {
      box-shadow: $list-focus-shadow;
    }
  }
}

.list-group-item {
  > .f-i {
    color: $list-group-item-icon-color;
  }

  .remove {
    &:hover {
      color: $list-group-remove-icon-hover-color;
    }
  }
}

.f-list-item-checkbox {
  .list-group-item {
    &.active:not(.list-group-item-hover) {
      background-color: transparent;
    }
  }

  .list-group-item-hover:has(.form-check-input:focus-visible) {
    & {
      background-color: $f-list-item-bg-focus;
      border-color: $f-list-item-border-color-focus;
      border-radius: $border-radius-1;
    }
  }
}

.list-group-selectable {
  > .list-group-item {
    background-color: $list-item-selectable-bg !important;
    border-color: $list-item-selectable-bc;
    border-radius: $border-radius-2;

    @media (hover: hover) {
      &:hover {
        background-color: $list-item-selectable-bg-hover !important;
        border-color: $list-item-selectable-bc-hover;
      }
    }

    &.selected {
      background-color: $list-item-selectable-selected-bg !important;
      border-color: $list-item-selectable-selected-bc;
    }

    &:first-child {
      border-top-left-radius: $border-radius-2;
      border-top-right-radius: $border-radius-2;
    }

    &:last-child {
      border-bottom-right-radius: $border-radius-2;
      border-bottom-left-radius: $border-radius-2;
    }
  }
}

.toggle-list-item {
  &:hover {
    background-color: $toggle-list-item-bg-hover;
  }
}

.toggle-list-item-checked {
  background-color: $toggle-list-item-checked-bg;
}

.toggle-list-item-icon-check {
  color: $toggle-list-item-icon-check-color;
}
