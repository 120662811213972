$nav-item-outline-bg: transparent;
$nav-item-outline-bc: $color-neutral-200;
$nav-item-outline-bd-color: $color-neutral-200;
$nav-item-outline-hover-bd-color: $color-neutral-300;
$nav-item-outline-active-bd-color: $color-neutral-400;
$nav-item-outline-disabled-bd-color: $color-neutral-100;
$nav-item-outline-active-disabled-bd-color: rgba($color-neutral-400, 0.3);
$nav-item-outline-disabled-bg: transparent;
$nav-item-outline-disabled-color: rgba($color-neutral-400, 0.3);
$nav-item-outline-active-disabled-color: rgba($color-neutral-700, 0.3);
$chip-choice-border-color: $color-neutral-500;
$nav-item-icon-color: $color-neutral-400;
$nav-item-icon-disabled-color: $color-neutral-400;
$nav-item-disabled-hover-color: rgba($color-neutral-700, 0.3);
$nav-item-disabled-hover-bg: $color-neutral-50;
