@import './nav-variables';
@import './nav-mixin';

.nav-tabs {
  .nav-item {
    @extend .chip;

    .f-i {
      color: $nav-item-icon-color;
    }

    &[disabled],
    &.disabled {
      .f-i {
        color: $nav-item-icon-disabled-color;
      }
    }

    &.active {
      &:hover {
        color: $nav-tabs-link-active-color;
        background-color: $nav-tabs-link-active-bg;
        border-color: $nav-tabs-link-active-border-color;
      }

      &[disabled],
      &.disabled {
        &:hover {
          color: $nav-item-disabled-hover-color;
          background-color: $nav-item-disabled-hover-bg;
        }
      }
    }
  }

  .nav-link:not(.active) {
    &[disabled],
    &.disabled {
      pointer-events: all;

      .f-i {
        color: $nav-item-icon-disabled-color;
      }
    }
  }
}

.nav-tabs.nav-tabs-outline {
  .nav-item {
    background-color: $nav-item-outline-bg;
    border-color: $nav-item-outline-bd-color;
    color: $nav-item-outline-color;

    &:hover {
      background-color: $nav-item-outline-bg;
      color: $nav-item-outline-hover-color;
      border-color: $nav-item-outline-hover-bd-color;
    }

    &:active,
    &:hover:active {
      background-color: $nav-item-outline-bg;
      color: $nav-item-outline-active-color;
      border-color: $nav-item-outline-active-bd-color;
    }

    &[disabled],
    &.disabled {
      @include nav-item-outline-disabled;

      &:hover,
      &:active,
      &:hover:active {
        @include nav-item-outline-disabled;
      }

      &.active {
        background-color: $nav-item-outline-disabled-bg;
        color: $nav-item-outline-active-disabled-color;
        border-color: $nav-item-outline-active-disabled-bd-color;
      }
    }

    &.chip-choice {
      background-color: $nav-item-outline-bg;
      border-color: $chip-choice-border-color;
    }

    &.active {
      @extend .chip-choice;

      &:hover {
        color: $nav-item-outline-active-color;
      }

      &[disabled],
      &.disabled {
        &:hover {
          color: $nav-item-outline-active-disabled-color;
          border-color: $nav-item-outline-active-disabled-bd-color;
        }
      }
    }
  }
}
