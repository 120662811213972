@import 'loading-variables';

.k-loading-mask {
  .k-loading-image {
    &::before {
      border-top-color: $k-loading-border-color;
      border-bottom-color: $k-loading-border-color;
      border-left-color: $k-loading-border-color;
      border-right-color: transparent;
    }
  }

  .k-loading-color {
    background-color: $k-loading-bg;
    opacity: $k-loading-overlay-opacity;
  }
}
