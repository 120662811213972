.f-i {
	font-family: "common";
}

@font-face {
	font-family:"common";
	src: url('../fonts/common.eot');
	src: url('../fonts/common.eot?#iefix') format('eot'),
	url('../fonts/common.woff') format('woff'),
	url('../fonts/common.ttf') format('truetype'),
	url('../fonts/common.svg#common') format('svg');
}
.f-i-a-to-z::before {
	content:'\E001';
}
.f-i-addressbook-add::before {
	content:'\E002';
}
.f-i-alignment::before {
	content:'\E003';
}
.f-i-arrow-down::before {
	content:'\E004';
}
.f-i-arrow-invited::before {
	content:'\E005';
}
.f-i-arrow-up::before {
	content:'\E006';
}
.f-i-bank::before {
	content:'\E007';
}
.f-i-bell::before {
	content:'\E008';
}
.f-i-bento-menu::before {
	content:'\E009';
}
.f-i-blocks::before {
	content:'\E00A';
}
.f-i-bold::before {
	content:'\E00B';
}
.f-i-book::before {
	content:'\E00C';
}
.f-i-building::before {
	content:'\E00D';
}
.f-i-bullet-list::before {
	content:'\E00E';
}
.f-i-bulleted-list::before {
	content:'\E00F';
}
.f-i-calendar-add::before {
	content:'\E010';
}
.f-i-calendar::before {
	content:'\E011';
}
.f-i-camera::before {
	content:'\E012';
}
.f-i-caret-double::before {
	content:'\E013';
}
.f-i-caret-down::before {
	content:'\E014';
}
.f-i-caret-left::before {
	content:'\E015';
}
.f-i-caret-right::before {
	content:'\E016';
}
.f-i-caret-up::before {
	content:'\E017';
}
.f-i-caution::before {
	content:'\E018';
}
.f-i-center-alignment::before {
	content:'\E019';
}
.f-i-check::before {
	content:'\E01A';
}
.f-i-chevron-left::before {
	content:'\E01B';
}
.f-i-chevron-right::before {
	content:'\E01C';
}
.f-i-clean::before {
	content:'\E01D';
}
.f-i-clip::before {
	content:'\E01E';
}
.f-i-clock::before {
	content:'\E01F';
}
.f-i-close::before {
	content:'\E020';
}
.f-i-collapse::before {
	content:'\E021';
}
.f-i-color-highlight::before {
	content:'\E022';
}
.f-i-compass::before {
	content:'\E023';
}
.f-i-contact-add::before {
	content:'\E024';
}
.f-i-contact::before {
	content:'\E025';
}
.f-i-copy::before {
	content:'\E026';
}
.f-i-crm-primary::before {
	content:'\E027';
}
.f-i-crm::before {
	content:'\E028';
}
.f-i-danger::before {
	content:'\E029';
}
.f-i-dashboard::before {
	content:'\E02A';
}
.f-i-dots-x::before {
	content:'\E02B';
}
.f-i-dots-y::before {
	content:'\E02C';
}
.f-i-download::before {
	content:'\E02D';
}
.f-i-drag-drop::before {
	content:'\E02E';
}
.f-i-edit::before {
	content:'\E02F';
}
.f-i-email::before {
	content:'\E030';
}
.f-i-enlarge::before {
	content:'\E031';
}
.f-i-ESG::before {
	content:'\E032';
}
.f-i-event-add::before {
	content:'\E033';
}
.f-i-event::before {
	content:'\E034';
}
.f-i-excel::before {
	content:'\E035';
}
.f-i-excluded-from-mail::before {
	content:'\E036';
}
.f-i-expand::before {
	content:'\E037';
}
.f-i-export::before {
	content:'\E038';
}
.f-i-external-link::before {
	content:'\E039';
}
.f-i-field-add::before {
	content:'\E03A';
}
.f-i-field::before {
	content:'\E03B';
}
.f-i-file-general::before {
	content:'\E03C';
}
.f-i-file-image::before {
	content:'\E03D';
}
.f-i-filter::before {
	content:'\E03E';
}
.f-i-flag::before {
	content:'\E03F';
}
.f-i-globe::before {
	content:'\E040';
}
.f-i-graph::before {
	content:'\E041';
}
.f-i-group::before {
	content:'\E042';
}
.f-i-header::before {
	content:'\E043';
}
.f-i-help::before {
	content:'\E044';
}
.f-i-home::before {
	content:'\E045';
}
.f-i-image::before {
	content:'\E046';
}
.f-i-info::before {
	content:'\E047';
}
.f-i-insight::before {
	content:'\E048';
}
.f-i-italic::before {
	content:'\E049';
}
.f-i-layers::before {
	content:'\E04A';
}
.f-i-line::before {
	content:'\E04B';
}
.f-i-link-redirect::before {
	content:'\E04C';
}
.f-i-link::before {
	content:'\E04D';
}
.f-i-list::before {
	content:'\E04E';
}
.f-i-mailing-address::before {
	content:'\E04F';
}
.f-i-management::before {
	content:'\E050';
}
.f-i-menu::before {
	content:'\E051';
}
.f-i-merge::before {
	content:'\E052';
}
.f-i-micro::before {
	content:'\E053';
}
.f-i-migrate::before {
	content:'\E054';
}
.f-i-minus-circle::before {
	content:'\E055';
}
.f-i-mixed-non-primary::before {
	content:'\E056';
}
.f-i-mixed-primary::before {
	content:'\E057';
}
.f-i-modified-data::before {
	content:'\E058';
}
.f-i-new-conversation::before {
	content:'\E059';
}
.f-i-new-to-old::before {
	content:'\E05A';
}
.f-i-notes::before {
	content:'\E05B';
}
.f-i-numbered-list::before {
	content:'\E05C';
}
.f-i-old-to-new::before {
	content:'\E05D';
}
.f-i-pdf::before {
	content:'\E05E';
}
.f-i-personalization::before {
	content:'\E05F';
}
.f-i-phone::before {
	content:'\E060';
}
.f-i-pin::before {
	content:'\E061';
}
.f-i-plane::before {
	content:'\E062';
}
.f-i-plus-circle::before {
	content:'\E063';
}
.f-i-plus::before {
	content:'\E064';
}
.f-i-pointer::before {
	content:'\E065';
}
.f-i-powerpoint::before {
	content:'\E066';
}
.f-i-primary-email::before {
	content:'\E067';
}
.f-i-primary-phone::before {
	content:'\E068';
}
.f-i-redo::before {
	content:'\E069';
}
.f-i-report::before {
	content:'\E06A';
}
.f-i-retry::before {
	content:'\E06B';
}
.f-i-right-alignment::before {
	content:'\E06C';
}
.f-i-search::before {
	content:'\E06D';
}
.f-i-send-message::before {
	content:'\E06E';
}
.f-i-settings::before {
	content:'\E06F';
}
.f-i-share::before {
	content:'\E070';
}
.f-i-shrink::before {
	content:'\E071';
}
.f-i-signature::before {
	content:'\E072';
}
.f-i-spell-check::before {
	content:'\E073';
}
.f-i-star-filled::before {
	content:'\E074';
}
.f-i-star::before {
	content:'\E075';
}
.f-i-stars-outlined::before {
	content:'\E076';
}
.f-i-stars::before {
	content:'\E077';
}
.f-i-success::before {
	content:'\E078';
}
.f-i-suggestion::before {
	content:'\E079';
}
.f-i-suitcase::before {
	content:'\E07A';
}
.f-i-sync-deleted::before {
	content:'\E07B';
}
.f-i-sync-disabled::before {
	content:'\E07C';
}
.f-i-sync-edited::before {
	content:'\E07D';
}
.f-i-sync-not-synced::before {
	content:'\E07E';
}
.f-i-sync-partially-synced::before {
	content:'\E07F';
}
.f-i-sync-synced::before {
	content:'\E080';
}
.f-i-sync::before {
	content:'\E081';
}
.f-i-table::before {
	content:'\E082';
}
.f-i-tag::before {
	content:'\E083';
}
.f-i-target::before {
	content:'\E084';
}
.f-i-text-color::before {
	content:'\E085';
}
.f-i-thumb-down::before {
	content:'\E086';
}
.f-i-thumb-up::before {
	content:'\E087';
}
.f-i-timezone::before {
	content:'\E088';
}
.f-i-toggles::before {
	content:'\E089';
}
.f-i-tools::before {
	content:'\E08A';
}
.f-i-trash-bin::before {
	content:'\E08B';
}
.f-i-underline::before {
	content:'\E08C';
}
.f-i-undo::before {
	content:'\E08D';
}
.f-i-url::before {
	content:'\E08E';
}
.f-i-view-agenda::before {
	content:'\E08F';
}
.f-i-view-day::before {
	content:'\E090';
}
.f-i-view-month::before {
	content:'\E091';
}
.f-i-view-week::before {
	content:'\E092';
}
.f-i-view-work-week::before {
	content:'\E093';
}
.f-i-warning::before {
	content:'\E094';
}
.f-i-width-alignment::before {
	content:'\E095';
}
.f-i-word::before {
	content:'\E096';
}
.f-i-z-to-a::before {
	content:'\E097';
}