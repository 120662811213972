@mixin btn-style(
  $bg,
  $color,
  $bd-color,
  $bg-hover,
  $color-hover,
  $bd-hover,
  $bg-focus-visible,
  $box-shadow-focus-visible,
  $color-focus-visible,
  $bg-pressed,
  $color-pressed,
  $bd-pressed,
  $color-focus,
  $bg-disabled,
  $color-disabled,
  $bd-disabled
) {
  background-color: $bg;
  color: $color;
  border-color: $bd-color;

  @media (hover: hover) {
    &:hover,
    &:hover:focus-visible,
    &:hover:focus {
      background-color: $bg-hover;
      color: $color-hover;
      border-color: $bd-hover;
    }
  }

  &:active,
  &:active:focus {
    background-color: $bg-pressed;
    box-shadow: none;
    color: $color-pressed;
    border-color: $bd-pressed;
  }

  &:focus {
    background-color: $bg;
    color: $color-focus;
  }

  @media (hover: hover) and (min-width: $grid-breakpoint-sm) {
    &:focus-visible {
      background-color: $bg-focus-visible;
      box-shadow: $box-shadow-focus-visible;
      color: $color-focus-visible;
    }
  }

  &:disabled {
    background-color: $bg-disabled;
    color: $color-disabled;
    border-color: $bd-disabled;

    &:hover {
      color: $color-disabled;
      border-color: $bd-disabled;
    }
  }
}
