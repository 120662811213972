$k-calendar-border-color: $color-neutral-100;
$k-calendar-text-color: $body-color;
$k-calendar-box-shadow: $shadow-neutral-900-level-4-properties
  rgba($color-neutral-900, $shadow-neutral-900-level-4-opacity);
$k-calendar-disabled-opacity: 0.3;
$k-calendar-focused-link-box-shadow: inset 0 0 0 1px $color-primary-500;
$k-calendar-today-link-box-shadow-hover: inset 0 0 0 1px $color-primary-700;
$k-calendar-content-bg: $color-base;
$k-calendar-selected-hover-bg: $color-primary-700;
$k-calendar-icon-color: $color-neutral-400;
$k-calendar-icon-hover-color: $color-primary-700;
$k-calendar-other-month-link-color: $color-neutral-400;
$k-calendar-range-bg: $color-base;
$k-calendar-header-color: $color-primary-700;
$k-calendar-range-mid-bg: rgba($color-primary-500, 0.25);
$k-button-opacity-disabled: 0.65;
$k-calendar-today-link-color-active: $color-primary-900;
$k-calendar-today-link-color-focus: $color-primary-700;
$k-calendar-today-link-color-disabled: $color-default-300;
$k-calendar-today-link-shadow-color: $color-primary-400;
$k-calendar-button-bs-color: $color-primary-400;
$k-calendar-button-bs: inset $shadow-focused-level-1-properties
  rgba($k-calendar-button-bs-color, $shadow-focused-level-1-opacity);
$k-mini-calendar-bg: $kendo-calendar-bg;
$k-calendar-nav-today-color: $color-primary-700;
$k-calendar-nav-today-color-hover: $color-primary-800;
$mobile-calendar-nav-fast-color: $color-neutral-700;
$mobile-calendar-expand-btn-icon-color: $color-default-200;
$mobile-calendar-expand-btn-border-color: $color-default-200;
