@import './popup-variables';

.k-popup {
  .k-overflow-container {
    .k-button:disabled {
      color: $color-neutral-400;
    }
  }

  .k-item {
    &.k-focus,
    &.k-selected {
      border-color: rgba($color-primary-600, $k-list-item-selected-opacity);
      background-color: $kendo-list-item-selected-bg;
      border-radius: $border-radius-1;
    }
  }

  .k-link.k-menu-link.k-selected {
    box-shadow: inset $list-focus-shadow;
    border-radius: $border-radius-1;
  }
}

.k-animation-container .k-popup {
  background-color: $k-animation-container-popup-bg;
  border-color: $k-animation-container-popup-border-color;
  box-shadow: $k-animation-container-popup-bs;
}
