@import './legend-variables';

.legend {
  &-price {
    background-color: $legend-price-color;
  }

  &-shares {
    background-color: $legend-shares-color;
  }

  &-info {
    color: $legend-info-color;
  }
}

.f-grouped-bar-chart-legend {
  @for $i from 1 through 10 {
    .legend-item:nth-child(#{$i}) .legend-marker {
      background-color: nth($grouped-bar-chart-legend-colors, $i);
    }
  }
}

.reversed .f-grouped-bar-chart-legend {
  @for $i from 1 through 10 {
    .legend-item:nth-last-child(#{$i + 1}) .legend-marker {
      background-color: nth($grouped-bar-chart-legend-colors, $i);
    }
  }
}

.f-grouped-bar-line-chart-legend {
  .legend-item:nth-child(1) .legend-marker {
    background-color: $line-stroke;
  }
  @for $i from 1 through 10 {
    .legend-item:nth-child(#{$i + 1}) .legend-marker {
      background-color: nth($grouped-bar-chart-legend-colors, $i);
    }
  }
}

.f-stacked-bar-chart-legend {
  @for $i from 1 through 10 {
    .legend-item:nth-child(#{$i}) .legend-marker {
      background-color: nth($stacked-bar-chart-legend-colors, $i);
    }
  }
}

.f-stacked-bar-line-chart-legend {
  .legend-item:nth-child(1) .legend-marker {
    background-color: $line-stroke;
  }
  @for $i from 1 through 10 {
    .legend-item:nth-child(#{$i + 1}) .legend-marker {
      background-color: nth($stacked-bar-chart-legend-colors, $i);
    }
  }
}
