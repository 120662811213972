@use 'sass:math';
@import 'firefly/styles/mixin';
@import './modals-variables';

$modal-modifiers: ('confirmation', 'warning', 'warning-merge', 'error');

.modal {
  @each $type in $modal-modifiers {
    &.#{$type} {
      .modal-header {
        &::before {
          @if ($type == 'confirmation') {
            background: $confirmation-bg;
          }
          @if ($type == 'error') {
            background: $error-bg;
          }
          @if ($type == 'warning') or (($type == 'warning-merge')) {
            background: $warning-bg;
          }
        }
        &::after {
          background: url('#{$brand-assets-common-path}' + '#{$type}-modal-icon.svg') no-repeat;
        }
      }
    }
  }
}

@include mobile-phones-only {
  .modal {
    @each $type in $modal-modifiers {
      &.#{$type} {
        .modal-header {
          &::after {
            background: url('#{$brand-assets-common-path}' + '#{$type}-modal-icon-mobile.svg') no-repeat;
          }
        }
      }
    }

    .modal-content {
      border-top-left-radius: $border-radius-2;
      border-top-right-radius: $border-radius-2;
    }
  }
}
