@import 'common-variables';
@import 'firefly/styles/mixin';

.f-bg-bubble {
  fill: $bubble-bg;
  background-color: $bubble-bg;

  @include mobile-phones-only {
    stroke: $bubble-stroke;
  }
}

.f-text-bubble {
  fill: $bubble-text;
  color: $bubble-text;
}

.f-rect-bar {
  cursor: pointer;
  fill: $rect-bar-fill;

  &.f-rect-bar-empty {
    fill: transparent !important;
    stroke: transparent !important;
  }
}

.f-bar-halo,
.f-bar-group-popover {
  cursor: pointer;
  fill: transparent;
}

[f-bar-series],
[f-grouped-bar-series],
[f-stacked-bar-series],
[f-positive-negative-bar-series],
[f-positive-negative-grouped-bar-series] {
  .interactive {
    .f-bar-halo {
      fill: $bar-halo-color;
    }
  }
}

[f-bar-series],
[f-grouped-bar-series] {
  .selected {
    .f-rect-bar {
      stroke: $rect-bar-selected-color;
    }
  }
}

.f-chart-rect-border {
  stroke: $chart-rect-border-color;
}

[f-bar-series] {
  &.has-selected-bar {
    @include bar-series-filters;
  }
  @include desktops-only {
    &:hover {
      @include bar-series-filters;
    }
  }
}

[f-stacked-bar-series],
[f-positive-negative-bar-series] {
  &.has-selected-bar {
    @include stacked-bar-series-filters;
  }
  @include desktops-only {
    &:hover {
      @include stacked-bar-series-filters;
    }
  }
}

[f-grouped-bar-series],
[f-positive-negative-grouped-bar-series] {
  &.has-selected-bar {
    @include grouped-bar-series-filters;
  }
  @include desktops-only {
    &:hover {
      @include grouped-bar-series-filters;
    }
  }
}
