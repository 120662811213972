@import 'variables';

.form-check .form-check-input {
  box-shadow: $box-shadow-inset;

  &:focus-visible {
    background-color: $form-check-bg-focus;
    box-shadow: $form-check-box-shadow;
  }

  &:active {
    background-color: $form-check-bg-pressed;
  }

  &:disabled {
    & ~ .form-check-label {
      color: $form-check-label-color-disabled;
    }
  }
}

.form-check-input {
  &[type='radio'] {
    &:hover {
      border-color: $form-check-input-bc-hover;
    }

    &:active:focus {
      border-color: $form-check-input-bc-pressed;
    }

    &:disabled {
      border-color: $form-check-input-bc-disabled;
    }

    &:checked {
      border-color: $form-radio-checked-bc;

      &::before {
        content: '';
        background: $input-checked-bg-color;
        border-radius: $border-radius-100;
      }

      &:hover::before {
        background: $input-checked-hovered-bg-color;
      }

      &:active:focus::before {
        background: $form-radio-checked-bg-pressed;
      }
    }

    &:disabled {
      &::before,
      &:checked::before {
        background: $form-radio-checked-bg-disabled;
      }

      & ~ .form-check-label {
        color: $input-radio-disabled-color;
      }
    }
  }

  &[type='checkbox'] {
    &:hover {
      border-color: $form-check-input-bc-hover;
    }

    &:active:focus {
      border-color: $form-check-input-bc-pressed;
    }

    &:disabled {
      border-color: $form-check-input-bc-disabled;
      opacity: 1;

      &::before {
        opacity: $form-check-input-disabled-opacity;
      }
    }

    &:indeterminate,
    &.indeterminate {
      background: $form-check-input-indeterminate-bg;
      &:hover {
        background: $form-check-input-indeterminate-bg-hover;
      }
      &:active {
        background: $form-check-input-indeterminate-bg-pressed;
      }
      &:focus-visible {
        box-shadow: $form-check-input-indeterminate-bs-focus;
      }
      &:disabled {
        background: $form-check-input-indeterminate-bg-disabled;
        opacity: 1;
      }
    }

    &:checked {
      background: $input-checked-bg-color;

      &::before {
        content: '';
        background-image: $input-checked-bg-image;
      }
      &:hover {
        @media (hover: hover) {
          background: $input-checked-hovered-bg-color;
        }
      }
      &:focus-visible {
        background-color: $input-checked-focused-bg-color;
        box-shadow: $form-check-checked-box-shadow-focus;
      }
      &:active:focus {
        background: $form-check-checked-bg-pressed;
      }
      &:disabled,
      &:disabled:hover {
        background-color: $form-check-checked-bg-disabled;
        opacity: 1;

        &::before {
          opacity: $form-check-input-disabled-opacity-checked;
          background-image: $input-checked-image-bg-disabled;
        }
      }
    }
  }
}

.form-switch {
  .form-check-input {
    background-color: $form-switch-bg;

    &:hover {
      background-color: $form-switch-bg-hover;
    }

    &:active:focus {
      background-color: $form-switch-bg-pressed;
    }

    &:disabled {
      background-color: $form-switch-bg-disabled;
    }

    &[type='checkbox']::before {
      background: $form-switch-circle-bg;
      border-radius: $border-radius-100;
    }

    &:checked {
      background: $input-checked-bg-color;
      &:hover {
        background: $input-checked-hovered-bg-color;
      }
      &:disabled {
        background: $input-checked-disabled-bg-color;

        &::before {
          opacity: $form-switch-disabled-opacity-checked;
        }
      }
    }

    &:disabled {
      background-color: $form-switch-bg-disabled;
      &::before {
        opacity: $form-switch-bg-opacity-disabled;
      }
    }
  }
}

.form-check-label {
  color: $form-check-label-color;
}
