@import './badge-variables';

$badges: (
  primary: (
    color: $badge-primary-color,
    background: $badge-primary-background
  ),
  neutral: (
    color: $badge-neutral-color,
    background: $badge-neutral-background
  ),
  neutral-secondary: (
    color: $badge-neutral-secondary-color,
    background: $badge-neutral-secondary-background,
    shadow: $badge-neutral-secondary-shadow
  ),
  success: (
    color: $badge-success-color,
    background: $badge-success-background
  ),
  success-secondary: (
    color: $badge-success-secondary-color,
    background: $badge-success-secondary-background
  ),
  warning: (
    color: $badge-warning-color,
    background: $badge-warning-background
  ),
  danger: (
    color: $badge-danger-color,
    background: $badge-danger-background
  ),
  accent-1: (
    color: $badge-accent-1-color,
    background: $badge-accent-1-background
  ),
  accent-2: (
    color: $badge-accent-2-color,
    background: $badge-accent-2-background
  ),
  accent-3: (
    color: $badge-accent-3-color,
    background: $badge-accent-3-background
  ),
  accent-4: (
    color: $badge-accent-4-color,
    background: $badge-accent-4-background
  ),
  accent-5: (
    color: $badge-accent-5-color,
    background: $badge-accent-5-background
  )
);

@each $type, $color in $badges {
  $color: map-deep-get($badges, $type, 'color');
  $background: map-deep-get($badges, $type, 'background');
  $shadow: map-deep-get($badges, $type, 'shadow');

  .badge-#{$type} {
    color: $color;
    background-color: $background;
    box-shadow: $shadow;
  }
}
