@import 'firefly/styles/functions';

$font-size-base: $font-base-size;
$line-height-base: get-line-height($font-base-size, $font-base-line-height);

$font-family-sans-serif: system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', 'Noto Sans', 'Liberation Sans',
  Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

$link-hover-decoration: underline;
$link-decoration: none;

$utilities: (
  'text-align': (
    responsive: true,
    property: text-align,
    class: text,
    values: left right center justify
  ),
  'color': (
    property: color,
    class: text,
    values: (
      null: null
    )
  ),
  'white-space': (
    property: white-space,
    class: text,
    values: (
      wrap: normal,
      nowrap: nowrap,
      prewrap: pre-wrap
    )
  ),
  'font-family': (
    property: font-family,
    class: font,
    values: (
      sans-serif: $font-family-sans-serif
    )
  )
);
