$axis-label-color: $color-neutral-400;
$axis-domain-color: $color-neutral-100;
$axis-zero-line-color: $color-neutral-400;
$rect-bar-fill: $color-accent-3-500;
$rect-bar-selected-color: $color-neutral-700;
$chart-rect-border-color: $color-neutral-100;
$line-stroke: $color-neutral-900;
$area-fill: $color-accent-3-100;
$area-fill-opacity: 1;
$area-stroke: $color-accent-3-700;
$area-pointer-line-stroke: $color-accent-3-700;
$area-pointer-line-stroke-selected: $color-neutral-700;
$svg-donut-chart-arc-fill-1: $color-accent-3-500;
$svg-donut-chart-arc-fill-2: $color-accent-6-900;
$svg-donut-chart-arc-fill-3: $color-warning-600;
$bar-halo-color: rgba($color-accent-3-100, 0.7);
$part-donut-empty-arc-bg: $color-neutral-100;
$stripes-pattern-color-1: $color-accent-3-300;
$stripes-pattern-color-2: $color-accent-3-500;
$stripes-mask-color: $color-base;
$f-stacked-bar-basis-bg: $color-base;
$non-selected-bar-saturate: 0.5;
$non-selected-bar-brightness: 1.1;
$non-selected-stacked-bar-brightness: 1.1;
$non-selected-stacked-bar-opacity: 1;
$grouped-bar-fill-1: $color-accent-3-300;
$grouped-bar-fill-2: $color-accent-3-400;
$grouped-bar-fill-3: $color-accent-3-500;
$grouped-bar-fill-4: $color-accent-3-600;
$grouped-bar-fill-5: $color-accent-3-700;
$grouped-bar-fill-6: $color-accent-3-800;
$bubble-bg: $color-accent-3-300;
$bubble-text: $color-accent-3-800;
$bubble-stroke: $color-base;
$bar-arrow-pointer-color: $color-default-700;
$scale-chart-bar-bg-5: $color-danger-600;
$positive-value-bar-color: $color-success-700;
$negative-value-bar-color: $color-accent-1-600;

$concentration-bar-highlighted-decile: $color-neutral-500;
$concentration-bar-decile-bubble: $color-danger-500;
$concentration-bar-chart-color-scheme-0-level-1: rgba($color-accent-3-300, 0.6);
$concentration-bar-chart-color-scheme-0-level-2: $color-accent-3-300;
$concentration-bar-chart-color-scheme-0-level-3: $color-accent-3-400;
$concentration-bar-chart-color-scheme-0-level-4: $color-accent-3-600;
$concentration-bar-chart-color-scheme-0-level-5: $color-accent-3-900;
$concentration-bar-chart-color-scheme-1-level-1: rgba($color-success-300, 0.6);
$concentration-bar-chart-color-scheme-1-level-2: rgba($color-success-400, 0.8);
$concentration-bar-chart-color-scheme-1-level-3: $color-success-500;
$concentration-bar-chart-color-scheme-1-level-4: $color-success-700;
$concentration-bar-chart-color-scheme-1-level-5: $color-success-900;
