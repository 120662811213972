@import 'cards-variables';

.card {
  box-shadow: $card-box-shadow;

  &.f-sub-cards-container {
    .f-sub-card {
      box-shadow: $sub-cards-box-shadow;
      position: relative;
    }

    & > .card-body > .card-text {
      .card {
        box-shadow: none;

        .card-title-text {
          @extend .body-70;
        }
      }
    }
  }
}

.card-large {
  border-top-left-radius: $card-large-border-radius-top-left;

  .card-footer {
    box-shadow: $card-footer-box-shadow;
  }
}

.card-link-show-more {
  &::before {
    background-image: linear-gradient(
      180deg,
      $card-link-show-more-gradient-start-color 0%,
      $card-link-show-more-gradient-end-color 60%
    );
  }
}
