.ca-highlighted-decile {
  fill: $concentration-bar-highlighted-decile;
  background-color: $concentration-bar-highlighted-decile !important;
}

.ca-selected-decile-bubble {
  fill: $concentration-bar-decile-bubble;
  background-color: $concentration-bar-decile-bubble !important;
}

@include concentration-bar-chart-color($concentration-bar-chart-color-map);
